import React, { useEffect, useState } from 'react'
import { FloatingLabel, Form, Modal, Table } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../Redux/hooks';
import { ModalsConstants } from '../../Redux/models/models-constants';
import { AssetItem } from '../../types/ApiTypes';
import { FaEdit, FaRegClock } from "react-icons/fa";
import ContentScheduleTiming from './content-schedule-timing';
import nodeService from '../../services/ApiServices/node-service';
import { useParams } from 'react-router-dom';
import { ContentItem } from '../../Redux/content/content-reducer';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { istToReadable } from '../../utils/helpers';


function ContentSchedule() {
    const isModal = useAppSelector(state => state.ModalReducer.isScheduleModal);
    const dispatch = useAppDispatch();
    const [sets, setSets] = useState<{ id: number, name: string }[]>([])
    const { nodeId } = useParams()

    const contentData = useAppSelector(state => state.ContentReducer.content) as ContentItem[]

    const handleClose = () => {
        dispatch({ type: ModalsConstants.IS_SCHEDULE_MODAL, payload: false });
    };

    useEffect(
        () => {
            nodeService.getNodeSets(nodeId ? +nodeId : 0).then(
                (res) => {
                    const data = res.data
                    setSets(data.map((d) => ({ id: d.id, name: d.name })))
                }
            ).catch(
                (err) => {
                    console.log("err ===>", err)
                }
            )
        }, []
    )

    function formatReadableDate(isoDate: string) {
        const date = new Date(isoDate);
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long', // Full month name (e.g., November)
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short', // Shows the timezone (e.g., GMT, UTC)
        };
        return date.toLocaleString('en-US', options);
    }
    
    function convertToLocalTime(givenDateString: string) {
        // Parse the input date string
        const date = new Date(givenDateString);
    
        // Format it for local time
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short', // Include local timezone
        };
    
        // Use toLocaleString to format the date in the user's local timezone
        return date.toLocaleString('en-US', options);
    }


    return (
        <Modal show={isModal} onHide={handleClose} size='xl' animation backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title
                    className='d-flex align-items-center'
                >
                    <span>
                        <FaRegClock />
                    </span>
                    &nbsp;
                    Schedule Content</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th style={{ minWidth: '235px' }}>Content</th>
                            {
                                sets.map(
                                    (set, index) => <th key={index} style={{ minWidth: '235px' }}>{set.name}</th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            contentData?.map(
                                (content, index) => (
                                    <tr key={index}>
                                        <td>{content?.name}</td>
                                        {
                                            sets.map(
                                                (set, index) => {
                                                    const value = content.setContentSchedule?.find((sd) => sd.setId === set.id)
                                                    const data = value ? [value.availableFrom, value.availableTill] : null
                                                    return (
                                                        <td key={index}>
                                                            {data ? (
                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                    <div className='d-flex flex-column align-items-start justify-content-between'>
                                                                        {data[0] ? <div className='d-flex align-items-start justify-content-between' >
                                                                            <strong>From:</strong> <div>{istToReadable(data[0])}</div>
                                                                        </div> : ''}
                                                                       {data[1] ? <div className='d-flex align-items-start justify-content-between'>
                                                                            <strong>To: &nbsp; &nbsp;</strong> <div>{istToReadable(data[1])}</div>
                                                                        </div> : ''}
                                                                    </div>

                                                                    <ContentScheduleTiming
                                                                        contentName={content?.name}
                                                                        set={set}
                                                                        contentId={content.id}
                                                                        scheduleData={content.setContentSchedule}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <ContentScheduleTiming
                                                                    contentName={content?.name}
                                                                    set={set}
                                                                    contentId={content.id}
                                                                    scheduleData={content.setContentSchedule}
                                                                />
                                                            )}
                                                        </td>
                                                    )
                                                }
                                            )
                                        }
                                    </tr>
                                )
                            )
                        }
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    )
}

export default ContentSchedule