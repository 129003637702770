import { useEffect, useState } from 'react';
import { Button, Modal, Form, FloatingLabel } from 'react-bootstrap';


type ServiesModalInterface = {
  show: boolean;
  setShow: (e: boolean) => void; // Explicitly set the type for the parameter
  handleSubmit: ({ name }: { name: string; }) => void; // Explicitly typed parameters for name and id,
  serviceName?: string,
  id?: number,
};



const ExamTargetModal = ({
  show,
  setShow,
  handleSubmit,
  serviceName,
  id,
}: ServiesModalInterface) => {

  const [name, setName] = useState('');

  const handleClose = () => setShow(false);

  const handleForm = () => {
    handleSubmit({ name })
    setName('')
  }

  useEffect(() => {
    if (serviceName) {
      setName(serviceName);
    } else {
      setName('');
    }
  }, [serviceName]);


  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{id ? `Edit Exam Target` : `Create Exam Target`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <FloatingLabel
                controlId="floatingInput"
                label="Exam Target Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Enter exam target name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FloatingLabel>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleForm}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExamTargetModal;
