"use client";

import React, { useState, useRef } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { GroupedQuestionData, QuestionData, QuizImportInterface } from "../../types/types";
import QuizService from "../../services/ApiServices/QuizService";
import { QuizPayload, quizQuestionType, QuizType } from "../../types/quizTypes";
import { pdfUploadHelper } from "../../utils/helpers";
import { MEDIA_BASE_URL } from "../../config";

function UploadQuizPage() {
  const typeRef = useRef<HTMLSelectElement>(null);
  const [loading, setLoading] = useState(false);
  const [testImages, setTestImages] = useState<{ key: string, fileName: string }[]>([]);
  const [jsondata, setJsondata] = useState<QuizImportInterface>();

  const [quizDetails, setQuizDetails] = useState<QuizPayload>({
    quizType: QuizType.QUIZ,
    internalName: "",
    showInstructions: typeRef.current?.value == "QUIZ" ? true : false,
    instructions: "",
    timeLimit: typeRef.current?.value == "QUIZ" ? 60 : 0,
    minTimeSubmission: typeRef.current?.value == "QUIZ" ? 10 : 0,
    totalAttempts: 0,
    showSolutions: true,
    showRank: true,
    showReport: true,
    language: "ENGLISH",
    postSubmissionMessage: "",
    sections: [],
  });


  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    toast.loading("Reading files...")
    const files = Array.from(e.target.files || []);
    const uploadedFiles: { key: string, fileName: string }[] = [];
    let JsonData;

    for (const file of files) {
      if (file.name === "assessmentData.json") {
        try {
          const jsonContent = await readFileContent(file);
          const data: QuizImportInterface = JSON.parse(jsonContent)
          const quizData = {
            quizType: typeRef.current?.value as QuizType,
            internalName: data["spayee:resource"]["spayee:title"],
            showInstructions: data["spayee:resource"]["spayee:instruction"].length == 0 ? false : true,
            instructions: data["spayee:resource"]["spayee:instruction"],
            timeLimit: typeRef.current?.value == "QUIZ" ? quizDetails.timeLimit : 0,
            minTimeSubmission: typeRef.current?.value == "QUIZ" ? quizDetails.minTimeSubmission : 0,
            totalAttempts: typeRef.current?.value == "QUIZ" ? 0 : 0,
            showSolutions: true,
            showRank: true,
            showReport: true,
            language: "ENGLISH",
            postSubmissionMessage: "",
            sections: data["spayee:resource"]["spayee:SectionGrouping"].map(
              (section, index) => ({
                id: index,
                sectionName: section.sectionName,
                maxQuestions: section.maxQuestions
              })
            )
          }

          setQuizDetails(quizData)
          JsonData = JSON.stringify(data)
        } catch (error) {
          toast.error("Failed to read JSON file");
        }
      } else {
        try {
          const key = await pdfUploadHelper(file);
          uploadedFiles.push({ key, fileName: file.name });
        } catch (err) {
          toast.error("Failed to upload file: " + file.name);
        }
      }
    }

    if (JsonData) {
      for (let i = 0; i < uploadedFiles.length; i++) {
        const uploadedFile = uploadedFiles[i]
        JsonData = JsonData?.replace("images/" + uploadedFile.fileName, MEDIA_BASE_URL + uploadedFile.key)
      }
      setJsondata(JSON.parse(JsonData));
      setTestImages(uploadedFiles);
      setLoading(false);
      toast.success("File readed successfully.")
    }
    else {
      toast.error("Data not found.")
      setLoading(false);
      return
    }
  };

  const readFileContent = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;

      reader.readAsText(file);
    });
  };

  function transformData(inputArray: (string | number)[] | undefined) {
    if (!inputArray) {
      alert("data not found.")
      return
    }
    const resultMap = new Map();

    inputArray.forEach((item, optionIndex) => {
      // Use regex to split the first part as index and the rest as matches
      //@ts-ignore
      const [index, matchesPart] = item.match(/^(\d+|[A-Za-z]+)(.*)$/).slice(1, 3);
      const matches = matchesPart.split(',').map((match: any) => match.trim()).filter(Boolean);

      // Update resultMap for each match option
      matches.forEach((matchOption: any) => {
        if (!resultMap.has(matchOption)) {
          resultMap.set(matchOption, {
            option: matchOption,
            matchOptionIndexes: []
          });
        }
        resultMap.get(matchOption).matchOptionIndexes.push(optionIndex);
      });
    });

    // Convert the resultMap values to an array
    return Array.from(resultMap.values());
  }


  const createTest = async () => {
    setLoading(true)
    toast.loading("creadting quiz...")
    try {
      const quizServiceResponse = await QuizService.createQuiz({
        ...quizDetails, 
        timeLimit: Number(quizDetails.timeLimit)*60,
        minTimeSubmission: Number(quizDetails.minTimeSubmission)*60,
        sections: quizDetails.sections.length ? quizDetails.sections : [{
          sectionName: "section",
          maxQuestions: 0
        }]
      })
      const quizData = quizServiceResponse.data
      const questions = jsondata?.["spayee:resource"]["spayee:questionIds"] ?? {}

      const paragraphs: { id: number, paragraph: string }[] = []

      const questionDataKeys: string[] = Object.keys(questions)
      const questionData: any[] = []

      for (let index = 0; index < questionDataKeys.length; index++) {
        const spyaeeQuestion = questions[questionDataKeys[index]]
        if ('questiongroup' in questions[questionDataKeys[index]].data) {
          const question = spyaeeQuestion as GroupedQuestionData
          const paragraphQuestions = question.data.question
          const paragraph = question.data.grouptext

          let paragraphId: number = -1;

          if (paragraphQuestions.length > 0) {
            paragraphId = paragraphs.find((para) => para.paragraph === paragraph)?.id || -1
          }
          if (paragraphId === -1) {
            const response = await QuizService.saveParagraphs(paragraph);
            paragraphs.push(response.data)
            paragraphId = response.data.id
          }

          for (let index = 0; index < paragraphQuestions.length; index++) {

            const paragraphQuestion = paragraphQuestions[index];
            const questionSolution = question.data.answer.find((ans) => ans.questionId === paragraphQuestion.id)

            const questionSpayeeType = paragraphQuestion.type
            const qType: quizQuestionType = questionSpayeeType === 'objective' ? 'SINGLE_CORRECT' :
              questionSpayeeType === 'multichoice' ? "MULTIPLE_CORRECT" :
                questionSpayeeType === 'numerical' ? 'NUMERICAL' :
                  questionSpayeeType === 'match' ? "MATCH_THE_COLUMN" : 'SUBJECTIVE';

            const qTypeLabel = qType === 'SINGLE_CORRECT' ? "SINGLE CORRECT TYPE" :
              qType === 'MULTIPLE_CORRECT' ? "ONE OR MORE THAN ONE CORRECT TYPE" :
                qType === 'NUMERICAL' ? "NUMERICAL TYPE" :
                  qType === 'SUBJECTIVE' ? 'SUBJECTIVE TYPE' :
                    qType === 'MATCH_THE_COLUMN' ? 'MATCH THE COLUMN TYPE' : ''

            const questionFormatedData = {
              paragraphId,
              qOrder: question.sequence,
              qType,
              qTypeLabel,
              language: 'English',
              sectionId: quizData.sections.length === 1 ? quizData.sections[0].id : quizData.sections.find((section) => section.sectionName === question.section?.split(',')[0])?.id,
              qInstructions: undefined,
              qText: paragraphQuestion.text,
              options: ['SINGLE_CORRECT', 'MULTIPLE_CORRECT', 'MATCH_THE_COLUMN'].includes(qType)
                ? paragraphQuestion.options.option.map(
                  (opt) => ({
                    qOption: opt.content,
                    optionOrder: opt.id,
                    isSolution: questionSolution?.correctOptions.option.includes(opt.id)
                  })
                )
                : [],
              qHint: "",
              qSolution: questionSolution?.solution.text ? questionSolution?.solution.text : "<div></div>",
              positiveMarks: +paragraphQuestion.mark,
              negativeMarks: +paragraphQuestion.penalty,
              partialMarks: 0,
              correctAnswer: qType === 'MATCH_THE_COLUMN' ? undefined : qType === "NUMERICAL" ? questionSolution?.correctOptions.option ? +questionSolution?.correctOptions.option : undefined : undefined,
              rangeFrom: 0,
              rangeTo: 0,
              level1Id: null,
              level2Id: null,
              matches: qType === 'MATCH_THE_COLUMN' ?
                transformData(questionSolution?.correctOptions.option)
                :
                undefined
            }
            questionData.push(questionFormatedData)
          }
        }
        else {
          const question = spyaeeQuestion as QuestionData
          const questionSpayeeType = question.data.type
          const qType: quizQuestionType = questionSpayeeType === 'objective' ? 'SINGLE_CORRECT' :
            questionSpayeeType === 'multichoice' ? "MULTIPLE_CORRECT" :
              questionSpayeeType === 'numerical' ? 'NUMERICAL' :
                questionSpayeeType === 'match' ? "MATCH_THE_COLUMN" : 'SUBJECTIVE';

          const qTypeLabel = qType === 'SINGLE_CORRECT' ? "SINGLE CORRECT TYPE" :
            qType === 'MULTIPLE_CORRECT' ? "ONE OR MORE THAN ONE CORRECT TYPE" :
              qType === 'NUMERICAL' ? "NUMERICAL TYPE" :
                qType === 'SUBJECTIVE' ? 'SUBJECTIVE TYPE' :
                  qType === 'MATCH_THE_COLUMN' ? 'MATCH THE COLUMN TYPE' : ''

          const questionFormatedData = {
            paragraphId: undefined,
            qOrder: question.sequence,
            qType,
            qTypeLabel,
            language: 'English',
            sectionId: quizData.sections.length === 1 ? quizData.sections[0].id : quizData.sections.find((section) => section.sectionName === question.section?.split(',')[0])?.id,
            qInstructions: undefined,
            qText: question.data?.text,
            options: ['SINGLE_CORRECT', 'MULTIPLE_CORRECT', 'MATCH_THE_COLUMN'].includes(qType)
              ? question.data.options.option.map(
                (opt) => ({
                  qOption: opt.content,
                  optionOrder: opt.id,
                  isSolution: question.data.answer.correctOptions.option.includes(opt.id)
                })
              )
              : [],
            qHint: "",
            qSolution: question.data.answer.solution?.text ? question.data.answer.solution?.text : "<div></div>",
            positiveMarks: +question.data.mark,
            negativeMarks: +question.data.penalty,
            partialMarks: 0,
            correctAnswer: qType === 'MATCH_THE_COLUMN' ? undefined : qType === "NUMERICAL" ? +question.data.answer.correctOptions.option : undefined,
            rangeFrom: 0,
            rangeTo: 0,
            level1Id: null,
            level2Id: null,
            matches: qType === 'MATCH_THE_COLUMN' ?
              transformData(question.data.answer.correctOptions.option)
              :
              undefined
          }
          questionData.push(questionFormatedData)
        }
      }

      await Promise.allSettled(questionData.map((question: any) => QuizService.addQuestion(quizServiceResponse.data.id, question))).catch((error) =>
        error.forEach((err: any) => console.error(err))
      );
      toast.success("Quiz Created Successfully. Quiz Id: " + quizServiceResponse.data.id)
      setLoading(false)
    } catch (error: any) {
      toast.error(JSON.stringify(error.message))
      setLoading(false)
    }
  };

  return (
    <div className="dashboard-page">
      <div className="container">
        Download test from Spayee and upload image files here.
        <br />
        <FloatingLabel className="my-3" controlId="quizType" label="Quiz Type">
          <Form.Select name="quizType" defaultValue="Quiz" ref={typeRef}>
            <option value={QuizType.QUIZ}>Quiz</option>
            <option value={QuizType.PRACTICE_QUIZ}>Practice Quiz</option>
          </Form.Select>
        </FloatingLabel>

                <FloatingLabel controlId="quizTimeLimit" label="Time Limit (Minutes)">
                  <Form.Control
                    type="number"
                    placeholder="In Minutes"
                    value={quizDetails.timeLimit}
                    className="my-3"
                    onChange={(e) => {
                      setQuizDetails(
                        (prev) => ({
                          ...prev,
                          timeLimit: +e.target.value
                        })
                      )
                    }}
                      // setQuizDetails("timeLimit", +e.target.value)}
                  />
                </FloatingLabel>

                <FloatingLabel
                  controlId="minTimeSubmission"
                  label="Min Time to Submission (Minutes)"
                >
                  <Form.Control
                  className="my-3"
                    type="number"
                    placeholder="In Minutes"
                    value={quizDetails.minTimeSubmission}
                    onChange={(e) => {
                      setQuizDetails(
                        (prev) => ({
                          ...prev,
                          minTimeSubmission: +e.target.value
                        })
                      )
                    }}
                    // onChange={(e) => handleChange("minTimeSubmission", +e.target.value)}
                  />
                </FloatingLabel>
        <input
          type="file"
          className="mb-3"
          onChange={handleFileUpload}
          multiple
          accept=".json,image/*"
        />
        <br />
        <Button disabled={loading} onClick={createTest}>
          Save Test
        </Button>
        {loading && <span className="wait"> Please Wait...</span>}
      </div>
    </div>
  );
}

export default UploadQuizPage;
