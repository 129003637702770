import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';
import { EnrolledStudentsHeader } from '../../components/headers';
import { BACKEND_URLS, ENDPOINT_HOST, FRONTEND_URLS } from '../../config';
import { axiosClient } from '../../services/axiosClient';
import {
    EnrolledStudent,
    EnrollmentFilteresInterface,
    metaDataInterface,
} from '../../types/enrolled-student';
import { conditionalStyles, enrollDataColumns } from './constants';

function EnrolledStudents() {
    const [loading, setLoading] = useState(true);
    const [students, setStudents] = useState<EnrolledStudent[]>([]);
    const [error, setError] = useState<string>('');
    const [metaData, setMetaData] = useState<metaDataInterface>({
        onboarded: 0,
        in_process: 0,
        not_started: 0
    });
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(100);
    const [filters, setFilters] = useState<EnrollmentFilteresInterface>({
        name: '',
        group: [],
        course_id: [],
        mentor_id: [],
        process: 'ONBOARDED',
        status: 'HASMENTORSHIP',
    });
    const [filterdData, setFilteredData] = useState<EnrolledStudent[]>([]);




    const [allSelected, setAllSelected] = useState(false); // The state for "select all" checkbox

    // Handle row selection change
    const handleSelectRow = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedStudents = [...students];
        updatedStudents[index].isSelected = e.target.checked;  // Update the selected status
        setStudents(updatedStudents);

        // Update the "select all" checkbox
        if (!e.target.checked) {
            setAllSelected(false);  // Uncheck "select all" if any student is deselected
        }
    };

    // Handle the "select all" checkbox toggle
    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedStudents = students.map((student) => ({
            ...student,
            isSelected: e.target.checked,
        }));
        setStudents(updatedStudents);
        setAllSelected(e.target.checked);
    };

    useEffect(() => {
        setLoading(true);
        setError('');
        axiosClient
            .get(`${ENDPOINT_HOST + BACKEND_URLS.ENROLLMENT}`, {
                headers: { 'Content-Type': 'application/json' },
            })
            .then((response) => {
                if (response.data && response.data.data) {
                    setStudents(response.data.data);
                    setFilteredData(response.data.data);
                }
            })
            .catch((error: any) => {
                toast.error('Error fetching data:', error);
                setError('Failed to fetch data.');
                setStudents([]);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        // filtering by name
        const filteredbyName = students.filter((stud) => {
            if (
                filters.name &&
                !stud.name?.toLowerCase().includes(filters.name.toLowerCase()) &&
                !stud.username.includes(filters.name) &&
                !stud.primary_email?.includes(filters.name)
            ) {
                return false;
            }
            return true;
        });

        // filtering by Academic Status
        const fillteringByAcademicStatus = filteredbyName.filter((stud) => {
            if (
                filters.status === 'HASMENTORSHIP' &&
                stud.has_mentorship &&
                stud.validity &&
                new Date(stud.validity) >= new Date()
            ) {
                return true;
            } else if (
                filters.status === 'NOMENTORSHIP' &&
                !stud.has_mentorship &&
                stud.validity &&
                new Date(stud.validity) >= new Date()
            ) {
                if (stud.x_days_usage >= 5 * 60 * 60) {
                    return true;
                }
                return false;
            } else if (
                filters.status === 'INACTIVE' &&
                stud.validity &&
                new Date(stud.validity) < new Date()
            ) {
                return true;
            } else if (
                filters.status === 'EXPIRED' &&
                stud.course_end_date &&
                new Date(stud.course_end_date) < new Date()
            ) {
                return true;
            } else if (filters.status === 'ALL') {
                return true;
            } else {
                return false;
            }
        });

        // filtering by Course
        const filteringByCourse = fillteringByAcademicStatus.filter((stud) => {
            if (filters.course_id.length && !filters.course_id.includes(stud.course_id)) {
                return false;
            }
            return true;
        });

        // filtering by Group
        const filteringByGroup = filteringByCourse.filter((stud) => {
            if (filters.group.length && !filters.group.includes(stud.group || '')) {
                return false;
            }
            return true;
        });

        // filtering by mentorId
        const filteringByMentorId = filteringByGroup.filter((stud) => {
            if (filters.mentor_id.length && !filters.mentor_id.includes(stud.mentor_id)) {
                return false;
            }
            return true;
        });

        //Recalculate `onboarded`, `in_process`, and `not_started` counts
        let onboarded = 0;
        let in_process = 0;
        let not_started = 0;

        for (let i = 0; i < filteringByMentorId.length; i++) {
            const s = filteringByMentorId[i];
            const completed = s?.completed_processes_count ?? 0;
            const total = s?.total_processes ?? 0;
            const pending = s?.pending_processes_count ?? 0;

            if (+completed === +total) {
                onboarded = onboarded + 1;
            } else if (+pending < +total && +pending > 0) {
                in_process = in_process + 1;
            } else if (+completed === 0 && +pending !== 0) {
                not_started = not_started + 1;
            }
        }

        // filtering by onBoarding Status
        const filteringByOnBoardingStatus = filteringByMentorId.filter((stud) => {
            const completed = stud?.completed_processes_count ?? 0;
            const total = stud?.total_processes ?? 0;
            const pending = stud?.pending_processes_count ?? 0;

            if (filters.process === 'ONBOARDED') {
                return +completed === +total;
            } else if (filters.process === 'INPROGRESS') {
                return +pending < +total && +pending > 0;
            } else if (filters.process === 'NOTSTARTED') {
                return +completed === 0 && +pending !== 0;
            }
            return true; // Return all students for `ALL` process filter
        });

        // updating data
        setFilteredData(filteringByOnBoardingStatus);
        setMetaData({ onboarded, in_process, not_started });
    }, [filters, students]);

    return (
        <div className="p-4 bg-light rounded">
            <h2 className="mb-4">Enrolled Students</h2>
            <EnrolledStudentsHeader metaData={metaData} setFilter={setFilters} filters={filters} />
            {error && <div className="alert alert-danger">{error}</div>}
            {loading ? (
                <div className="text-center py-5">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : (
                <>
                 
                    <div className="position-relative mt-4" >
                        <DataTable
                            columns={enrollDataColumns(handleSelectRow,handleSelectAll,allSelected)}
                            data={filterdData}
                            pagination
                            paginationPerPage={rowsPerPage}
                            paginationRowsPerPageOptions={[100, 500, 1000]}
                            onChangePage={(newPage) => setPage(newPage)}
                            onChangeRowsPerPage={(newRowsPerPage) => {
                                setRowsPerPage(newRowsPerPage);
                            }}
                            customStyles={{
                                header: { style: { fontSize: '16px', fontWeight: 'bold' } },
                                rows: { style: { backgroundColor: '#fff' } },
                                pagination: {
                                    style: {
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        position: 'absolute',
                                        top: '-50px',
                                        width: '100%',
                                    },
                                },
                                headCells: {
                                    style: {
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        color: '#2f3640',
                                        borderBottom: '2px solid #dcdde1',
                                    },
                                },
                            }}
                            conditionalRowStyles={conditionalStyles}
                            pointerOnHover
                            highlightOnHover
                            noDataComponent={
                                <div className="text-center py-4">
                                    <h5>No data available</h5>
                                    <p>Adjust filters to see more results</p>
                                </div>
                            }
                            onRowClicked={(row) => {
                                const url = `${FRONTEND_URLS.STUDENT.ENROLLED_STUDENTS}/${row.enrollment_id}`;
                                window.open(url, '_blank');
                            }}

                        />

                    </div>

                </>
            )}
        </div>
    );
}

export default EnrolledStudents;
