import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import demoCoursesServices from '../../services/demo-courses-services';

interface CourseInterface {
    id: number;
    classId: number;
    targetId: number;
    courseId: number;
    isDeleted: boolean;
    createdById: string | null;
    isDemo: boolean;
    demoDurationInHrs: number;
    createdOn: string;
    updatedOn: string;
    courseName: string;
    targetName: string;
    className: string;
}

function DemoCourses() {
    const [coursesData, setCoursesData] = useState<CourseInterface[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const handleIsDemoToggle = async (id: number) => {
        // Optimistically update the UI
        const updatedCourses = coursesData.map((course) =>
            course.id === id ? { ...course, isDemo: !course.isDemo } : course
        );

        setCoursesData(updatedCourses);

        // Make API call to persist the change
        const targetCourse = updatedCourses.find((course) => course.id === id);
        try {
            if (targetCourse) {
                await demoCoursesServices.updateIsDemoCourse({
                    courseId: targetCourse.id,
                    isDemo: targetCourse.isDemo,
                });
                toast.success('Course updated successfully!');
            }
        } catch (error) {
            // Revert the change if the API fails
            const revertedCourses = coursesData.map((course) =>
                course.id === id ? { ...course, isDemo: !course.isDemo } : course
            );
            setCoursesData(revertedCourses);
            toast.error('Failed to update the course.');
        }
    };

    const coursesColumns = [
        {
            name: 'Course Name',
            selector: (row: CourseInterface) => row.courseName,
            cell: (row: any) => <div
                data-tag="allowRowEvents"
                style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            > {row.courseName} </div>
        },
        {
            name: 'Class Name',
            selector: (row: CourseInterface) => row.className,
            cell: (row: any) => <div
                data-tag="allowRowEvents"
                style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            > {row.className} </div>
        },
        {
            name: 'Target Name',
            selector: (row: CourseInterface) => row.targetName,
            cell: (row: any) => <div
                data-tag="allowRowEvents"
                style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            > {row.targetName} </div>
        },
        {
            name: 'Is Demo',
            cell: (row: CourseInterface) => (
                <Form.Check
                    type="checkbox"
                    checked={row.isDemo}
                    onChange={() => handleIsDemoToggle(row.id)}
                />
            ),
        },
    ];

    useEffect(() => {
        setLoading(true);
        demoCoursesServices
            .getCoursesData()
            .then((res) => {
                if (res.status === 'success') {
                    setCoursesData(res.data.targets);
                } else {
                    toast.error(res.message);
                }
            })
            .catch((err) => toast.error('Failed to load courses.'))
            .finally(() => setLoading(false));
    }, []);

    return (
        <div className='mx-4'>
            <h2>Demo Courses</h2>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <DataTable
                    pagination
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[100, 500, 1000]}
                    columns={coursesColumns}
                    data={coursesData}
                    customStyles={{
                        table: {
                            style: {
                                width: '90%',
                                margin: 'auto'
                            }
                        }
                    }}
                />
            )}
        </div>
    );
}

export default DemoCourses;
