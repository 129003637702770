// General-purpose helper functions that can be used in multiple places

import axios from 'axios';
import { BACKEND_URLS, MEDIA_BASE_URL } from '../config';
import { axiosClient } from '../services/axiosClient';
import { AssetItem, GroupedAsset } from '../types/ApiTypes';
import { VideoSource } from '../types/types';
import { liveMangmentTabsType, SessionInterface } from '../types/LiveTypes';
import { NodeContentType } from '../types/node.types';
import { ContentItem } from '../Redux/content/content-reducer';

const validateString = (s: string | null) => !!s && !!s.toString().trim();
const validateNumber = (n: number | null) => n !== null && n !== undefined && n >= 0 && !isNaN(n);

const reciveDateTimeFormate = (d: string) => {
    if (!d) return '';
    const date = new Date(d);
    return date.toISOString().split('T');
};

const SendDateTimeFormate = (d: string) => {
    if (!d) return '';
};

const groupDataWithChildren = (data: AssetItem[]): GroupedAsset[] => {
    const groupedData: { [key: number]: GroupedAsset } = {};

    data?.forEach((item) => {
        const level2Id = item?.level2Id;
        const level2Name = item?.level2Name;

        if (!groupedData[level2Id]) {
            groupedData[level2Id] = {
                id: level2Id,
                name: level2Name,
                children: [],
            };
        }

        groupedData[level2Id].children?.push({
            id: item.id,
            name: item.name,
            assetType: item.assetType,
            assetId: item.assetId,
            type: item.type,
            level2Id: item.level2Id,
            level2Name: item.level2Name,
            level3Id: item.level3Id,
            parentAssetType: item.parentAssetType,
            parentAssetId: item.parentAssetId,
            createdOn: item.createdOn,
            isFree: item.isFree,
            assetData: item.assetData,
            order: item.order,
            isNew: item.isNew ?? false,
            isImported: item.isImported ?? false,
            newLevel2Id: item.newLevel2Id ?? -1,
            newLevel2Name: item.newLevel2Name ?? '',
        });
    });

    return Object.values(groupedData);
};

const pdfUploadHelper = async (file: any, onUploadProgress?: (e: any) => void) => {
    if (!file) {
        throw new Error('No file provided');
    }

    const config = {
        onUploadProgress: (progressEvent: any) => {
            if (progressEvent.lengthComputable) {
                const percentComplete: any = (progressEvent.loaded / progressEvent.total) * 100;
                onUploadProgress && onUploadProgress(parseInt(percentComplete));
            }
        },
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };

    const res = await axiosClient.post(
        BACKEND_URLS.UPLOADS_PDF,
        {
            files: file,
        },
        config
    );

    return res.data.data.key;
};

interface UploadOptions {
    file: File;
    onUploadProgress?: (e: any) => void;
    customName?: string;
    vdoCipherId?: VideoSource;
}

async function uploadVideoOnVdoCipherDirect({
    file,
    onUploadProgress,
    customName = '',
    vdoCipherId = VideoSource.VDOCIPHER_SECURE,
}: UploadOptions): Promise<{ fileName: string; videoId: string } | undefined> {
    try {
        const fileName = customName || file.name;

        const res = await axiosClient.get(
            `${BACKEND_URLS.UPLOADS}/${fileName.split('.')[0]}/${
                vdoCipherId ?? VideoSource.VDOCIPHER_SECURE
            }`
        );

        if (res?.status === 200 && res?.data?.status === 'success') {
            const data = res.data.data;
            const clientPayload = data?.clientPayload;

            const formData = new FormData();

            formData.append('policy', clientPayload.policy);
            formData.append('key', clientPayload.key);
            formData.append('x-amz-signature', clientPayload['x-amz-signature']);
            formData.append('x-amz-algorithm', clientPayload['x-amz-algorithm']);
            formData.append('x-amz-date', clientPayload['x-amz-date']);
            formData.append('x-amz-credential', clientPayload['x-amz-credential']);
            formData.append('success_action_status', '201');
            formData.append('success_action_redirect', '');
            formData.append('file', file);

            if (clientPayload?.uploadLink) {
                const config = {
                    onUploadProgress: (progressEvent: any) => {
                        if (progressEvent.lengthComputable) {
                            const percentComplete: any =
                                (progressEvent.loaded / progressEvent.total) * 100;
                            if (onUploadProgress) {
                                onUploadProgress(parseInt(percentComplete));
                            }
                        }
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                };

                const uploadToVideoCipher = await axios.post(
                    clientPayload.uploadLink,
                    formData,
                    config
                );
                return { fileName, videoId: data.videoId };
            } else {
                throw Error('upload link is missing.');
            }
        }
    } catch (error) {
        throw error;
    }
}

function nextCallSort(rowA: any, rowB: any) {
    const a = new Date(rowA.next_call);
    const b = new Date(rowB.next_call);
    return a < b ? -1 : a > b ? 1 : 0;
}
function lastCallSort(rowA: any, rowB: any) {
    const a = new Date(rowA.last_call);
    const b = new Date(rowB.last_call);
    return a < b ? -1 : a > b ? 1 : 0;
}

function secureNumber(number: any) {
    return number.toString().replace(/(\d{3})\d+(\d{3})/, '$1XXXX$2');
}

const secToHHMMSS = (sec: any, long = true) => {
    const hh = Math.floor(sec / 3600);
    const mm = Math.floor((sec % 3600) / 60);
    const ss = Math.floor(sec % 60);
    // if (!long)
    //     return `${hh > 9 ? hh : '0' + hh}:${mm > 9 ? mm : '0' + mm}:${ss > 9 ? ss : '0' + ss}`;
    return `${hh > 9 ? hh : '0' + hh}:${mm > 9 ? mm : '0' + mm}:${ss > 9 ? ss : '0' + ss}`;
    // return `${hh > 9 ? hh : '0' + hh}:${mm > 9 ? mm : '0' + mm}`;
    // return `${hh > 9 ? hh : '0' + hh}:${mm > 9 ? mm : '0' + mm}`;
};

type Poster = {
    width: number;
    height: number;
    posterUrl: string;
};

type PosterUrls = {
    hrUrl: string;
    lrUrl: string;
};

function getPosterUrls(data: Poster[]): PosterUrls {
    // Handle the case with no posters
    if (data.length === 0) {
        return { hrUrl: '', lrUrl: '' }; // Return empty strings or handle as needed
    }

    // If only one poster, set hr and lr to the same poster
    if (data.length === 1) {
        const singlePoster = data[0];
        return { hrUrl: singlePoster.posterUrl, lrUrl: singlePoster.posterUrl };
    }

    let hrPoster: Poster = {
        width: 0,
        height: 0,
        posterUrl: '',
    }; // High-resolution poster
    let lrPoster: Poster = {
        width: Infinity, // Initialize to a high value
        height: 0,
        posterUrl: '',
    }; // Low-resolution poster

    data.forEach((item) => {
        // Set hrPoster to the largest resolution (based on width)
        if (item.width > hrPoster.width) {
            hrPoster = item;
        }

        // Set lrPoster to the smallest resolution (based on width)
        if (item.width < lrPoster.width) {
            lrPoster = item;
        }
    });

    return {
        hrUrl: hrPoster.posterUrl,
        lrUrl: lrPoster.posterUrl,
    };
}

const formatDateTimeLocal = (dateString: string) => {
    // Parse the date string (ISO format)
    const date = new Date(dateString);

    // Get year, month, day, hours, and minutes
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // month is zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Return the formatted string 'YYYY-MM-DDTHH:MM'
    return `${year}-${month}-${day}T${hours}:${minutes}`;
};

function getAcadmicesSessions(): {
    label: string;
    value: string;
}[] {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const sessions = [
        {
            label: `${currentYear - 1}-${currentYear}`,
            value: `${currentYear - 1}-${currentYear}`,
        },
        {
            label: `${currentYear}-${currentYear + 1}`,
            value: `${currentYear}-${currentYear + 1}`,
        },
    ];
    return sessions;
}

const getMediaUrl = (url?: string) => {
    if (!url) return url;
    if (url.toString().startsWith('http')) return url;
    return MEDIA_BASE_URL + url;
};

function openFullscreen() {
    var elem: any = document.documentElement;
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
    }
}

function crmCopyEditCode(questionId: any) {
    const code = `<div class="iframe-container"><iframe allowfullscreen="" frameborder="0" height="600" src="http://api.esaral.com/v2/quizes/video-solution-html-view/${questionId}" width="800"></iframe></div>
      <p>&nbsp;</p>
      <style type="text/css">.iframe-container {
          position: relative;
          width: 100%;
          padding-bottom: 56.25%; /* 16:9 aspect ratio (9 / 16 * 100%) */
          overflow: hidden;
      }
      /* Iframe itself */
      .iframe-container iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 0; /* Remove iframe border */
      }
      </style>`;
    return navigator.clipboard.writeText(code);
}

function stripTags(text: string | null): string | null {
    if (!text) return null; // Handles null, undefined, or empty input.
    // Regex to match tags except <img>
    const strippedString = text
        .replace(/<(?!img\b)[^>]+>/gi, '') // Removes tags except <img>
        .trim();
    return strippedString ? strippedString : null;
}

function getSessionDiff(start: string, end: string) {
    return Number(end) - Number(start);
}

function convertUTCtoIST(utcDateString: string) {
    // Create a Date object from the UTC string
    const utcDate = new Date(utcDateString);
    // Add 5 hours and 30 minutes (IST offset from UTC)
    const istOffset = 5 * 60 + 30; // IST offset in minutes
    const istDate = new Date(utcDate.getTime() + istOffset * 60 * 1000);
    return istDate.toISOString().substring(0, istDate.toISOString().length - 1); // Returns in ISO format (adjusted to IST)
}

function convertISTtoUTC(istDateString: string) {
    // Create a Date object assuming the input is in IST (GMT+5:30)
    const istDate = new Date(istDateString + '+05:30');
    // Convert to UTC
    const utcDate = new Date(istDate.toISOString());
    return utcDate.toISOString(); // Returns UTC time in ISO format
}

// Format 'yyyy-mm-dd' to 'dd/mm/yyyy'
const formatDate = (dateString: string) => {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
};

function istToReadable(
    istTimestamp: string,
    isYear: boolean = false,
    includeTime: boolean = true // New parameter with default value true
): string {
    // Parse the IST timestamp into a Date object
    const istDate = new Date(istTimestamp);

    // Format the IST date in a readable format
    const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        timeZone: 'Asia/Kolkata', // Ensure the IST timezone is used
    };

    // Add year to the format options if isYear is true
    if (isYear) {
        options.year = '2-digit';
    }

    // Add time-related options only if includeTime is true
    if (includeTime) {
        options.hour = '2-digit';
        options.minute = '2-digit';
        options.hour12 = true;
    }

    return istDate.toLocaleString('en-IN', options);
}

function formatDateFromTimestamp(timestamp: number): string {
    // Create a Date object from the timestamp
    const date = new Date(timestamp * 1000);

    // Define an array of month abbreviations
    const monthAbbreviations = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    // Get the day, month, and year
    const day = date.getDate();
    const month = monthAbbreviations[date.getMonth()];
    const year = date.getFullYear();

    // Return the formatted date
    return `${day} ${month} ${year}`;
}

function transformAssetData(data: NodeContentType): any {
    return {
        id: data?.contentId ?? -1, // Default to -1 if null or undefined
        name: data?.internalName ?? '', // Default to an empty string
        assetType: data?.assetType ?? '', // Default to an empty string
        assetId: data?.assetId ?? -1, // Default to -1
        type: data?.type ?? '', // Default to an empty string
        level2Id: data?.level2Id ?? null, // Default to -1
        level2Name: data?.level2InternalName ?? '', // Default to an empty string
        level3Id: data?.level3Id ?? null, // Default to -1
        parentAssetType: null, // Assuming null as no mapping provided
        parentAssetId: null, // Assuming null as no mapping provided
        isFree: false, // Default to false
        assetData: {
            id: data?.assetId ?? -1, // Default to -1
            source: data?.source ?? '', // Default to an empty string
            videoId: data?.videoId ?? '', // Default to an empty string
            fileName: data?.internalName ?? '', // Default to an empty string
            thumbnailHr: data?.thumbnailHr ?? '', // Default to an empty string
            thumbnailLr: data?.thumbnailLr ?? '', // Default to an empty string
            durInSec: data?.durInSec ?? 0, // Default to 0
            internalName: data?.internalName ?? '', // Default to an empty string
            createdAt: data?.assetCreatedOn ?? '',
            decryptedFileUrl: data?.decryptedFileUrl ?? '',
            encryptedFileUrl: data?.encryptedFileUrl ?? '',
            password: data?.password ?? '',
            updatedAt: data?.assetCreatedOn ?? '',
        },
        order: 1, // Default order of 1
        isNew: false, // Default to false
        isImported: false, // Default to false
        newLevel2Id: -1, // Default to -1
        newLevel2Name: '', // Default empty string
    };
}

const randomSearch = (val: string, search: string) => {
    const tokens = search.split(/\s+/);

    return tokens.every((e) => val.toLowerCase().includes(e));
};

const preprocessWords = (str: string) => new Set(str.trim().toLowerCase().split(/\s+/));

const fuzzyMatch = (value: string, str: string) => {
    let lastIndex = -1;
    for (const char of value) {
        lastIndex = str.indexOf(char, lastIndex + 1);
        if (lastIndex === -1) return false;
    }
    return true;
};

const fuzzyUnorderedSearch = (val: string, search: string) => {
    const wordsInVal = preprocessWords(val);
    const tokens = search.trim().toLowerCase().split(/\s+/);
    return tokens.every((token) => Array.from(wordsInVal).some((word) => fuzzyMatch(token, word)));
};

export {
    groupDataWithChildren,
    pdfUploadHelper,
    validateNumber,
    validateString,
    reciveDateTimeFormate,
    SendDateTimeFormate,
    nextCallSort,
    lastCallSort,
    secureNumber,
    secToHHMMSS,
    uploadVideoOnVdoCipherDirect,
    getPosterUrls,
    formatDateTimeLocal,
    getAcadmicesSessions,
    getMediaUrl,
    openFullscreen,
    crmCopyEditCode,
    stripTags,
    getSessionDiff,
    convertISTtoUTC,
    convertUTCtoIST,
    istToReadable,
    formatDate,
    formatDateFromTimestamp,
    transformAssetData,
    randomSearch,
    fuzzyUnorderedSearch,
};
