import React, { useEffect, useState } from 'react'
import { axiosClient } from '../../services/axiosClient';
import { BACKEND_URLS } from '../../config';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { personalDetailsType } from '../../pages/bd/order/order-form';
import { STATES } from '../../utils/constants';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

export default function PersonalDetails({
    personalDetails,
    setPersonalDetails,
    setStaffId,
    student,
    salesPerson,
    setSalesPerson,
    setStudent
}: {
    personalDetails: personalDetailsType,
    setPersonalDetails: React.Dispatch<React.SetStateAction<personalDetailsType>>,
    setStaffId: (e: any) => void,
    student: any,
    salesPerson: any,
    setSalesPerson: (e: any) => void,
    setStudent: (e: any) => void
}) {
    const animatedComponents = makeAnimated();

    const [mobileNumbers, setMobileNumber] = useState<{ value: any, label: string }[]>([])
    const [selesPersons, setSalesperson] = useState<{ value: any, label: string }[]>([])
    const [mobileLoading, setMobileLoading] = useState<boolean>(false)
    const [salesPersonLoading, setsalesPersonLoading] = useState<boolean>(false)
    const { orderId } = useParams()

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement & HTMLInputElement>) => {
        const { id, value } = event.target
        setPersonalDetails(
            (prevDetatils) => ({
                ...prevDetatils,
                [id]: value
            })
        )
    };

    const fetchMobileNumber = async (inputValue: string) => {
        setMobileLoading(true);
        try {
            const response = await axiosClient.get(BACKEND_URLS.GET_USERS + `?search=${inputValue}&limit=10`);
            const users = response.data.data.users.map(
                (user: any) => ({
                    value: user,
                    label: `${user.username} (${user.name})`
                })
            )
            setMobileNumber(users); // Adjust based on your API response structure
        } catch (error: any) {
            toast.error('Error fetching data:', error.message);
        } finally {
            setMobileLoading(false);
        }
    };

    const fetchSalesPerson = async (inputValue: string) => {
        setsalesPersonLoading(true);
        try {
            const response = await axiosClient.get(BACKEND_URLS.GET_USERS + `?search=${inputValue}&isStaff=true`);
            const users = response.data.data.users.map(
                (user: any) => ({
                    value: user,
                    label: `${user.username} (${user.name})`
                })
            )
            setSalesperson(users);
        } catch (error: any) {
            toast.error('Error fetching data:', error);
        } finally {
            setsalesPersonLoading(false);
        }
    };

    const handleChange = (selectedOption: any) => {
        setPersonalDetails(
            (prevDetatils) => ({
                ...prevDetatils,
                mobileNumber: selectedOption.value.id
            })
        )
        setStudent({ label: `${selectedOption.value.username} (${selectedOption.value.name})`, value: selectedOption.value.id })
    };

    const handleSalesPersoneChange = (selectedOption: any) => {
        setPersonalDetails(
            (prevDetatils) => ({
                ...prevDetatils,
                salesPerson: selectedOption.value.id
            })
        )
        setSalesPerson({ label: `${selectedOption.value.username} (${selectedOption.value.name})`, value: selectedOption.value.id })
        setStaffId(selectedOption.value.id)
    };

    const handleStates = (value: any) => {
        setPersonalDetails(
            (prevDetatils) => ({
                ...prevDetatils,
                state: value.label
            })
        )
    }

    return (
        <Card className='m-2'>
            <Card.Body>
                <Row id="user-details">
                    <Row>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Student</Form.Label>
                                <Select
                                    value={student}
                                    components={animatedComponents}
                                    options={mobileNumbers}
                                    onInputChange={(inputValue) => {
                                        if (inputValue) {
                                            fetchMobileNumber(inputValue);
                                        }
                                    }}
                                    onChange={handleChange}
                                    isLoading={mobileLoading}
                                    placeholder="Type to search..."
                                    id='mobileNumber'
                                    isDisabled={orderId !== '0'}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Sales Person</Form.Label>
                                <Select
                                    value={salesPerson}
                                    components={animatedComponents}
                                    options={selesPersons}
                                    onInputChange={(inputValue) => {
                                        if (inputValue) {
                                            fetchSalesPerson(inputValue);
                                        }
                                    }}
                                    onChange={handleSalesPersoneChange}
                                    isLoading={salesPersonLoading}
                                    placeholder="Type to search..."
                                    id='selesPersons'
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        {/* <Col md={6}>
                <Form.Group>
                  <Form.Label>Student Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter student number" id="studentName" onChange={handleSelectChange} value={personalDetails.studentName} />
                </Form.Group>
              </Col> */}
                        <Col md={6}>
                            <Form.Group >
                                <Form.Label>Order Date</Form.Label>
                                <Form.Control type="date" id="orderDate" onChange={handleSelectChange} value={personalDetails.orderDate} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label> 
                                    {/* <div className='d-flex justify-content-between'> */}
                                        {/* <div> */}
                                            State
                                            {/* </div>  */}
                                        {/* <div><a href='https://knowindia.india.gov.in/states-uts/' target='_blank'> For State info. refer here. </a></div> */}
                                    {/* </div> */}
                                </Form.Label>
                                <Select
                                    components={animatedComponents}
                                    options={STATES}
                                    onChange={handleStates}
                                    defaultValue={{ "value": personalDetails.state, "label": personalDetails.state }}
                                    placeholder="Type to search..."
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className='d-flex flex-column justify-content-center'>
                            <Form.Group>
                                <Form.Label>Comment</Form.Label>
                                <Form.Control type="text" placeholder="Enter your comment here" id="comment" onChange={handleSelectChange} value={personalDetails.comment} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Row>
            </Card.Body>
        </Card>
    )
}