import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { pdfUploadHelper } from "../../utils/helpers";
import { axiosClient } from "../../services/axiosClient";
import { BACKEND_URLS, FRONTEND_URLS } from "../../config";
import { ContentConstants } from "../../Redux/content/content-constants";
import { ModalsConstants } from "../../Redux/models/models-constants";
import { Formik } from "formik";
import { Button, Form, ProgressBar } from "react-bootstrap";
import * as yup from 'yup';
import { useState } from "react";
import toast from "react-hot-toast";


const UploadPdf = () => {

    const schema = yup.object().shape({
        // internalName: yup.string().required('Internal name is required'),
        file: yup.mixed().required('A file is required')
    });

    const selectedChapter = useSelector<any>(
        (state) => state.ContentReducer.selectedChapter
    ) as { level2Id: number; level2Name: number };

    const { nodeId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pdfProgress, setPdfProgress] = useState(0)


    const handleUploadPdf = async (values: any) => {

        const pdfUrl = await pdfUploadHelper(values.file, setPdfProgress)

        try {
            const assetId = await axiosClient.post(
                BACKEND_URLS.ASSET_CREATE_PDF, {
                "internalName": values.file.name,
                "fileName": values.file.name,
                "decryptedFileUrl": `${pdfUrl}`,
            }
            )

            const content = {
                "isNew": true,
                "id": -1,
                "name": values.file.name,
                "assetType": "PDF",
                "assetId": assetId.data.data.id,
                "type": "",
                "level2Id": selectedChapter.level2Id,
                "level2Name": selectedChapter.level2Name,
                "level3Id": null,
                "parentAssetType": "null",
                "parentAssetId": 0,
                "isFree": false,
                "createdOn": "",
                "assetData": {
                    "id": assetId.data.data.id,
                    "internalName": values.internalName,
                    "fileName": values.internalName,
                    "decryptedFileUrl": pdfUrl,
                    "encryptedFileUrl": null,
                    "password": null
                }
            }

            dispatch({ type: ContentConstants.ADD_CONTENT, payload: content })
            dispatch({ type: ModalsConstants.IS_Modal, payload: false })
            navigate(FRONTEND_URLS.CONTENTS.EDIT_NODE + `/${nodeId}` + '/' + FRONTEND_URLS.CONTENTS.ASSET + '/' + content.id)
        } catch (error: any) {
            toast.error(error)
        }

    }

    return (
        <Formik
            validationSchema={schema}
            onSubmit={handleUploadPdf}
            initialValues={{
                internalName: '',
                file: null
            }}
        >
            {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit} className="p-2">
                    {/* File Upload */}
                    <Form.Group controlId="floatingInput" className="mb-3 row align-items-center">
                        <Form.Label className='col-sm-3 col-form-label'>Upload PDF File</Form.Label>
                        <div className='col-sm-9'>
                            <Form.Control
                                type="file"
                                name="file"
                                onChange={(event) => {
                                    const file = (event.target as HTMLInputElement).files?.[0];
                                    setFieldValue("file", file);
                                }}
                                accept="application/pdf"
                                isInvalid={touched.file && !!errors.file}
                            />
                            <div className="my-2">
                                <ProgressBar now={pdfProgress} label={`${pdfProgress}%`} animated striped />
                            </div>
                            <Form.Control.Feedback type="invalid" className="text-danger">
                                {errors.file}
                            </Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Button variant="primary" type="submit">Add</Button>
                </Form>
            )}
        </Formik>
    )
}


export default UploadPdf