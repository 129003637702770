import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import { axiosClient } from "../../services/axiosClient";
import { BACKEND_URLS, FRONTEND_URLS } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { ContentConstants } from "../../Redux/content/content-constants";
import { ModalsConstants } from "../../Redux/models/models-constants";
import * as yup from 'yup';
import { QuizItemResponse } from "../../types/quizTypes";
import { useNavigate, useParams } from "react-router-dom";
import { AssetType } from "../../types/types";
import toast from "react-hot-toast";
import nodeServices from "../../services/node.services";
import { NodeContentType } from "../../types/node.types";
import { transformAssetData } from "../../utils/helpers";


type quizType = 'PRACTICE_QUIZ' | 'QUIZ' | ''


const ImportQuiz = () => {
  const schema = yup.object().shape({
    assetId: yup.string(),
    search: yup.string(),
    subject: yup.string(),
    chapter: yup.string(),
  });

  const selectedChapter = useSelector<any>(state => state.ContentReducer.selectedChapter) as { level2Id: number, level2Name: number };

  const [data, setData] = useState<NodeContentType[]>([]);
  const [selectedContent, setSelectedContent] = useState<any>()
  const [selectedRow, setSelectedRow] = useState(null);

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { nodeId } = useParams();

  const handleAddContent = () => {
    const data = transformAssetData(selectedContent)
    let contentId = -1
    if  (selectedContent.level2Id == selectedChapter.level2Id) {
        contentId = selectedContent.contentId
        dispatch({ type: ContentConstants.ADD_CONTENT, payload: { ...data, "isNew": true, isImported: true, id: selectedContent.contentId, level2Id: selectedChapter.level2Id, level2Name: selectedChapter.level2Name, newLevel2Id: selectedContent.level2Id, newLevel2Name: selectedContent.level2Name} })
    }
    else {
        contentId = -1
        dispatch({ type: ContentConstants.ADD_CONTENT, payload: { ...data, "isNew": true, isImported: true, level2Id: selectedChapter.level2Id, level2Name: selectedChapter.level2Name, id: -1, newLevel2Id: selectedContent.level2Id, newLevel2Name: selectedContent.level2Name} })
    }
    dispatch({ type: ModalsConstants.IS_Modal, payload: false })
    navigate(FRONTEND_URLS.CONTENTS.EDIT_NODE + `/${nodeId}` + '/' + FRONTEND_URLS.CONTENTS.ASSET + '/' + contentId)
}

  const handleFilter = async (values: {
    search?: string;
    quizType?: quizType
    contentId?: string
  }) => {
    setLoading(true);
    // let query = '';
    // if (values.search) {
    //   query = query +`internalName=${values.search}`
    // }
    // if (values.quizType) {
    //   query = query + `quizType=${values.quizType}`
    // }

    try {
      const {data} = await nodeServices.getContentType({
        assetType:'quiz',
        name: values.search,
        quizType: values.quizType,
        contentId: values?.contentId ?? ''
        // level1Id: values.subject,
        // level2Id: values.chapter
    })

      // const { data } = await axiosClient.get(BACKEND_URLS.QUIZ + `?${query.join('&')}`);
      setData(data);
      setLoading(false);
    } catch (error: any) {
      toast.error(error.message)
      setLoading(false);
    }
  };

  const columns: TableColumn<NodeContentType>[] = [
    {
      name: 'Content Id',
      selector: (row) => row.contentId,
      cell: (row) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.contentId}</div>
    },
    {
      name: 'Internal Name',
      selector: (row) => row.internalName,
      cell: (row) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.internalName}</div>,
      style: {
        minWidth: '200px'
      }
    },
    {
      name: 'Chapter',
      selector: (row) => `${row.level2InternalName}`,
      cell: (row) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.level2InternalName}</div>
    },
    {
      name: 'Created On',
      selector: (row) => row.assetCreatedOn.split('T')[0],
      cell: (row) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.assetCreatedOn.split('T')[0]}</div>
    },
  ]


  const handleRowSelected = ({ selectedRows }: { selectedRows: any }) => {
    setSelectedRow(selectedRows.length > 0 ? selectedRows[0].id : null);
    setSelectedContent(selectedRows[0])
  };

  const conditionalRowStyles = [
    {
      when: (row: any) => row.id === selectedRow,
      style: {
        backgroundColor: '#d4edda',
        color: 'black',
      },
    },
  ];

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={(values) => {
          handleFilter(values);
        }}
        initialValues={{
          search: '',
          quizType: '' as quizType,
          contentId: ''
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit} className='d-flex flex-wrap justify-content-between p-2'>
            {/* Search input */}
            <Form.Group controlId="contentId" className="m-1 mb-2 col-md-3">
              <Form.Control
                type="text"
                placeholder="Search By content id"
                name="contentId"
                value={values.contentId}
                onChange={handleChange}
                isInvalid={touched.contentId && !!errors.contentId}
              />
              <Form.Control.Feedback type="invalid" className="text-danger">
                {errors.contentId}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="validationFormik04" className="m-1 mb-2 col-md-3">
              <Form.Control
                type="text"
                placeholder="Search here..."
                name="search"
                value={values.search}
                onChange={handleChange}
                isInvalid={touched.search && !!errors.search}
              />
              <Form.Control.Feedback type="invalid" className="text-danger">
                {errors.search}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Quiz Type Select */}
            <Form.Group className="m-1 mb-2 col-md-3">
              <Form.Select
                name='quizType' // Name should match initialValues key
                onChange={(e) => {
                  handleChange(e); // Pass the full event to Formik
                }}
                value={values.quizType}
                isInvalid={touched.quizType && !!errors.quizType}
              >
                <option value="">---------- Select -----------</option>
                {['QUIZ', 'PRACTICE_QUIZ'].map((quizType) => (
                  <option key={quizType} value={quizType}>
                    {quizType}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid" className="text-danger">
                {errors.quizType}
              </Form.Control.Feedback>
            </Form.Group>

            <Button type='submit' variant="primary" className="m-1 mb-2 col-md-3" disabled={loading}>{loading? "Searching...":"Filter"}</Button>
          </Form>
        )}
      </Formik>
      <hr />
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationPerPage={100}
        paginationRowsPerPageOptions={[100, 500, 1000]}
        selectableRowsSingle
        onSelectedRowsChange={handleRowSelected}
        selectableRows
        conditionalRowStyles={conditionalRowStyles}
      />

      <Button disabled={!selectedContent} onClick={handleAddContent} variant="success" className="mt-3">Add</Button>
    </>
  );
};

export default ImportQuiz