import EnrolledStudents from "./enrolled-students";
import StudentInfo from "./student-info";
import UserDetails, { loader as UserDetailsLoader } from "./user-details";

const Students = {
    EnrolledStudents,
    StudentInfo,
    UserDetails,
    UserDetailsLoader
}

export default Students