import { useContext, useState } from 'react';
import { PiStudentBold } from 'react-icons/pi';
import { FaBusinessTime, FaSignOutAlt } from 'react-icons/fa';
import { BiSolidBookContent } from 'react-icons/bi';
import { TbReport } from 'react-icons/tb';
import { CiFlag1 } from 'react-icons/ci';
import { Menu } from '../types/types';
import {
    Accordion,
    AccordionContext,
    ListGroup,
    Offcanvas,
    useAccordionButton,
} from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InitialStateType } from '../Redux/nav-bar/nav-bar';
import { NavBarConstants } from '../Redux/nav-bar/nav-bar-constants';
import { IoIosArrowDropleftCircle, IoIosArrowForward, IoIosArrowUp } from 'react-icons/io';
import { FRONTEND_URLS } from '../config';

type Props = {
    menu: Menu;
    showMenu: boolean;
    onClick: (e: any) => void;
};

type MenuItemType = {
    name: string | React.ReactNode;
    children?: MenuItemType[];
    onClick?: () => void;
    navigationPath?: string;
};

const NavIcons = [
    <PiStudentBold key={'student'} className="mx-2" size={20} />,
    <FaBusinessTime key={'business'} className="mx-2" size={20} />,
    <BiSolidBookContent key={'book'} className="mx-2" size={20} />,
    <TbReport className="mx-2" key={'report'} size={20} />,
    <CiFlag1 className="mx-2" size={20} key={'flag'} />,
];

const Menus: MenuItemType[] = [
    {
        name: 'Engagement',
        children: [
            {
                name: 'Doubt Tool',
                children: [],
                onClick: () => console.log('Subject Groups'),
                navigationPath: FRONTEND_URLS.ENGAGMENT.DOUBT_TOOL,
            },
            {
                name: 'Notification',
                children: [],
                onClick: () => console.log('Notification Groups'),
                navigationPath: FRONTEND_URLS.ENGAGMENT.NOTIFICATION,
            },
        ],
    },
    {
        name: 'Students',
        children: [
            {
                name: 'All',
                children: [],
                onClick: () => console.log('Subject Groups'),
                navigationPath: FRONTEND_URLS.ALL_USERS,
            },
            {
                name: 'Enrolled Students',
                children: [],
                onClick: () => console.log('Subject Groups'),
                navigationPath: FRONTEND_URLS.STUDENT.ENROLLED_STUDENTS,
            },
        ],
    },
    {
        name: 'Bd',
        children: [
            {
                name: 'Order',
                children: [],
                onClick: () => console.log('Subject Groups'),
                navigationPath: FRONTEND_URLS.BD.ORDER,
            },
            {
                name: 'Payments',
                children: [],
                onClick: () => console.log('Subjects'),
                navigationPath: FRONTEND_URLS.BD.PAYMENTS,
            },
        ],
    },
    {
        name: 'Content',
        children: [
            {
                name: 'Node',
                children: [],
                onClick: () => console.log('Subject Groups'),
                navigationPath: FRONTEND_URLS.CONTENTS.NODE,
            },
            {
                name: 'Set',
                children: [],
                onClick: () => console.log('Subjects'),
                navigationPath: FRONTEND_URLS.CONTENTS.SETS,
            },
            {
                name: 'Courses',
                children: [],
                onClick: () => console.log('Chapters'),
                navigationPath: FRONTEND_URLS.CONTENTS.COURSES,
            },
            {
                name: 'Quiz',
                children: [],
                navigationPath: FRONTEND_URLS.CONTENTS.QUIZ,
            },
            {
                name: 'Live Dashboard',
                children: [],
                navigationPath: FRONTEND_URLS.CONTENTS.LIVE_MANAGMENT + `/live`,
            },
        ],
    },
    {
        name: 'Admin',
        children: [
            {
                name: 'Academic',
                children: [
                    {
                        name: 'Subject Groups',
                        children: [],
                        onClick: () => console.log('Subject Groups'),
                        navigationPath: FRONTEND_URLS.ADMIN.LEVEL + '/0',
                    },
                    {
                        name: 'Subjects',
                        children: [],
                        onClick: () => console.log('Subjects'),
                        navigationPath: FRONTEND_URLS.ADMIN.LEVEL + '/1',
                    },
                    {
                        name: 'Chapters',
                        children: [],
                        onClick: () => console.log('Chapters'),
                        navigationPath: FRONTEND_URLS.ADMIN.LEVEL + '/2',
                    },
                    {
                        name: 'Content Groups',
                        children: [],
                        onClick: () => console.log('Content Groups'),
                        navigationPath: FRONTEND_URLS.ADMIN.LEVEL + '/3',
                    },
                    {
                        name: 'Exam Target',
                        children: [],
                        onClick: () => console.log('Content Groups'),
                        navigationPath: FRONTEND_URLS.ADMIN.EXAM_TARGETS,
                    },
                    {
                        name: 'Student Classes',
                        children: [],
                        onClick: () => console.log('Content Groups'),
                        navigationPath: FRONTEND_URLS.ADMIN.STUDENT_CLASSES,
                    },
                    {
                        name: 'Collection',
                        children: [],
                        navigationPath: FRONTEND_URLS.CONTENTS.COLLECTION,
                    },
                ],
                onClick: () => console.log('Academic'),
                navigationPath: FRONTEND_URLS.ADMIN.LEVEL + '/0',
            },
            {
                name: 'Enrollment',
                children: [
                    {
                        name: 'Services',
                        children: [],
                        onClick: () => console.log('Content Groups'),
                        navigationPath: FRONTEND_URLS.ADMIN.SERVICES,
                    },
                    {
                        name: 'Processes',
                        children: [],
                        onClick: () => console.log('Content Groups'),
                        navigationPath: FRONTEND_URLS.ADMIN.PROCESSES,
                    },
                    {
                        name: 'Hardwares',
                        children: [],
                        onClick: () => console.log('Content Groups'),
                        navigationPath: FRONTEND_URLS.ADMIN.HARDWARES,
                    },
                    {
                        name: 'Demo Courses',
                        children: [],
                        onClick: () => console.log('Demo Courses'),
                        navigationPath: FRONTEND_URLS.ADMIN.DEMO_COURSES,
                    },
                ],
                onClick: () => console.log('Content Groups'),
                navigationPath: FRONTEND_URLS.ADMIN.SERVICES,
            },
        ],
    },
    {
        name: 'Profile',
        children: [
            {
                name: 'Setting',
                children: [],
                onClick: () => console.log('Setting'),
                navigationPath: FRONTEND_URLS.SETTING,
            },
            {
                name: (
                    <span
                        onClick={() => {
                            localStorage.clear();
                            window.location.reload();
                        }}
                        className="nav-bar-span"
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                    >
                        <FaSignOutAlt style={{ marginRight: '8px' }} />
                        Logout
                    </span>
                ),
                children: [],
                onClick: () => console.log('Subject Groups'),
                navigationPath: FRONTEND_URLS.CONTENTS.NODE,
            },
        ],
    },
];

const CustomToggle = ({ children, eventKey }: any) => {
    const { activeEventKey } = useContext(AccordionContext);
    const isCurrentEventKey = activeEventKey === eventKey;

    const decoratedOnClick = useAccordionButton(eventKey);
    return (
        <div
            className="d-flex align-items-center justify-content-between"
            onClick={decoratedOnClick}
            style={{ cursor: 'pointer' }}
        >
            <Link to="#" className="nav-link">
                {children}
            </Link>
            {isCurrentEventKey ? <IoIosArrowUp color="#fff" /> : <IoIosArrowForward color="#fff" />}
        </div>
    );
};

const renderMenuItems = (items: MenuItemType[], depth = 0) => {
    return items.map((item, index) => (
        <ListGroup.Item as="li" bsPrefix="nav-item" key={`${depth}-${index}`}>
            {item.children && item.children.length > 0 ? (
                <Accordion>
                    <CustomToggle eventKey={`${depth}-${index}`}>{item.name}</CustomToggle>
                    <Accordion.Collapse eventKey={`${depth}-${index}`}>
                        <ListGroup as="ul" className="nav flex-column ms-3">
                            {renderMenuItems(item.children, depth + 1)}
                        </ListGroup>
                    </Accordion.Collapse>
                </Accordion>
            ) : (
                <NavLink to={item.navigationPath ?? '/'} style={{ textDecoration: 'none' }}>
                    {({ isActive }) => (
                        <ListGroup.Item
                            key={index}
                            as="li"
                            bsPrefix="nav-item"
                            className={`ps-3 my-1 nav-bar-li ${isActive ? 'active-link' : ''}`}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            {item.name}
                        </ListGroup.Item>
                    )}
                </NavLink>
            )}
        </ListGroup.Item>
    ));
};

const MenuItems = () => (
    <Accordion defaultActiveKey="0" as="ul" className="navbar-nav flex-column mt-3">
        <ListGroup.Item
            as="li"
            bsPrefix="nav-item"
            className="mb-2"
            style={{ alignSelf: 'center' }}
        >
            <img src={require('../assets/images/esaral_logo.png')} style={{ width: '48px' }} />
        </ListGroup.Item>
        {renderMenuItems(Menus)}
    </Accordion>
);

function NavbarVertical() {
    const [isOpen, setIsOpen] = useState(true);

    const show = useSelector<{ NavBarReducer: InitialStateType }>(
        (state) => state?.NavBarReducer.isNavbar
    );
    const dispatch = useDispatch();

    const handleClose = () =>
        dispatch({ type: NavBarConstants.IS_NAVBAR, action: { payload: false } });

    const toggleSidebar = () => setIsOpen(!isOpen);

    return (
        <>
            <div className={`navbar-vertical d-none d-md-block ${isOpen ? 'open' : 'closed'}`}>
                <div
                    className="position-absolute toggle-button"
                    style={{ right: '-30px', bottom: '0px' }}
                >
                    <IoIosArrowDropleftCircle
                        onClick={toggleSidebar}
                        color="black"
                        size={30}
                        className={`nav-bar-vertical-toggle ${
                            isOpen ? 'rotate-left' : 'rotate-right'
                        }`}
                    />
                </div>
                <div style={{ width: '250px', height: '100vh', overflow: 'auto' }} className="p-3">
                    <MenuItems />
                </div>
            </div>
            <div className="d-none d-sm-block">
                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton style={{ background: 'black', color: 'white' }} />
                    <Offcanvas.Body style={{ background: 'black' }}>
                        <MenuItems />
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    );
}

export default NavbarVertical;
