import { TargetItem } from '../../types/course.types';
import ApiService, { ApiResponse } from './api-service';

class TargetService extends ApiService {
    constructor() {
        super('/targets');
    }

    getAll(): ApiResponse<{ targets: TargetItem[] }> {
        return this.getData('');
    }
}

export default new TargetService();
