import React, { useState, useEffect } from 'react';
import { Button, Tabs, Tab } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useLoaderData } from 'react-router-dom';
import settingServices from '../../services/setting.services';
import MainBtn from '../../components/btn/main-btn';
import { useAppDispatch, useAppSelector } from '../../Redux/hooks';
import { ModalsConstants } from '../../Redux/models/models-constants';
import SettingModal from '../../components/modals/setting-modal';
import { settingInterface } from '../../types/types';
import PaymentGateways from '../../components/payment-gateway';

export async function loader(): Promise<settingInterface[]> {
    const data = await settingServices.getSettings();
    return Object.entries(data.data).map(([key, value]) => {
        return {
            [key]: value as settingInterface,
        };
    });
}

function Setting() {
    const data = useLoaderData() as settingInterface[];
    const dispatch = useAppDispatch();
    const [settings, setSettings] = useState<settingInterface[]>([]);
    const [currentSetting, setCurrentSetting] = useState<{
        key: string;
        value: string | (number | string)[] | settingInterface;
    } | null>(null);
    const isSettingModal = useAppSelector((state) => state.ModalReducer.isSettingModal);
    const [activeTab, setActiveTab] = useState<string>('settings');

    useEffect(() => {
        setSettings(data);
    }, [data]);

    const handleOpen = () => dispatch({ type: ModalsConstants.IS_SETTING_MODAL, payload: true });
    const handleClose = () => {
        setCurrentSetting(null);
        dispatch({ type: ModalsConstants.IS_SETTING_MODAL, payload: false });
    };

    const columns: TableColumn<settingInterface>[] = [
        {
            name: 'Key',
            cell: (row) => (
                <div
                    style={{
                        maxWidth: '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'wrap',
                    }}
                >
                    {Object.keys(row)[0]}
                </div>
            ),
            width: '200px',
        },
        {
            name: 'Value',
            cell: (row) => (
                <div
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'wrap',
                    }}
                >
                    {JSON.stringify(Object.values(row)[0])}
                </div>
            ),
        },
        {
            name: 'Action',
            cell: (row) => (
                <Button
                    onClick={() => {
                        setCurrentSetting({
                            key: Object.keys(row)[0],
                            value: Object.values(row)[0],
                        });
                        handleOpen();
                    }}
                    style={{
                        width: '60px',
                    }}
                >
                    Edit
                </Button>
            ),
            width: '100px',
        },
    ];

    return (
        <div className="tabbed-settings">
            {isSettingModal && (
                <SettingModal
                    data={currentSetting}
                    handleClose={handleClose}
                    setSettings={setSettings}
                />
            )}

            <Tabs
                id="settings-tabs"
                activeKey={activeTab}
                onSelect={(key) => setActiveTab(key!)}
                className="mb-3"
                transition={true}
            >
                <Tab eventKey="settings" title="Settings">
                    <DataTable
                        columns={columns}
                        data={settings}
                        title={'Settings'}
                        customStyles={{
                            table: {
                                style: {
                                    width: '90%',
                                    margin: 'auto',
                                },
                            },
                        }}
                    />
                    <MainBtn message="Create new setting" onClick={handleOpen} />
                </Tab>

                <Tab eventKey="payment" title="Payment Gateway">
                    <PaymentGateways />
                </Tab>
            </Tabs>
        </div>
    );
}

export default Setting;
