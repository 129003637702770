import React, { useEffect, useState, useMemo } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import sessionService from '../../../services/ApiServices/session-service';
import { liveMangmentTabsType, nodeContentMappingInterface, SessionInterface } from '../../../types/LiveTypes';
import { Button, Modal } from 'react-bootstrap';
import FormModal from '../../../components/formModal';
import { istToReadable } from '../../../utils/helpers';
import LiveManagmentContent from '../../../components/live-managment/live-managment';
import { Sessions } from '../../../components/node';
import { ModalsConstants } from '../../../Redux/models/models-constants';
import { useAppDispatch, useAppSelector } from '../../../Redux/hooks';
import { LiveManagementConstants } from '../../../Redux/live-mangment/live-mangment-constant';
import toast from 'react-hot-toast';
import { FaExternalLinkAlt } from 'react-icons/fa';
import CreateSessionModel from '../../../components/modals/create-session-model';

const HandleNodeIds = ({ ids }: { ids: nodeContentMappingInterface[] }) => {
    return (
        <div
            data-tag="allowRowEvents"
            style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
            }}
        >
            {ids.map((node) => (
                <div key={node.nodeId}>
                    <a
                        className="me-1"
                        href={`/edit-node/${node.nodeId}/content/${node.contentId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={node?.nodeInternalName ?? ''}
                    >
                        {node?.nodeInternalName}
                    </a>
                </div>
            ))}
        </div>
    );
};


const LiveManagement: React.FC = () => {
    const location = useLocation();
    const naivgate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [showSessionModel, setShowSessionModel] = useState(false);
    const [filteredSessions, setFilteredSessions] = useState<SessionInterface[]>([]);
    const [zoomMeetingIds, setZoomMeetingIds] = useState<Set<number>>(new Set());
    const selectedTab = location.pathname.split('/')[3] as liveMangmentTabsType;
    const [curentSession, setCurrentSession] = useState<SessionInterface>();
    const dispatch = useAppDispatch();
    const isModal = useAppSelector((state) => state?.ModalReducer?.isModal);

    const sessions = useAppSelector((state) => state.liveMangment?.sessions) as SessionInterface[];

    // Function to filter and sort data based on the selected tab
    const filterAndSortSessions = (data: SessionInterface[]) => {
        const filteredData = data.filter((session) => {
            switch (selectedTab) {
                case 'live':
                    return session.status === 'LIVE';
                case 'schdeuled':
                    const sessionEndTimeSeh = new Date(session.endTime);
                    const currentTimeSeh = new Date();
                    if (session.status === 'SCHEDULED' && sessionEndTimeSeh >= currentTimeSeh) {
                        return true;
                    }
                    return false;
                case 'completed':
                    const sessionEndTimeComp = new Date(session.endTime);
                    const currentTimeComp = new Date();
                    if (session.status === 'SCHEDULED' && sessionEndTimeComp < currentTimeComp) {
                        return true;
                    }
                    return session.status === 'COMPLETED';
                case 'cancelled':
                    return session.status === 'CANCELLED';
                default:
                    return false;
            }
        });

        return filteredData;
    };

    useEffect(() => {
        const getSession = () =>
            sessionService
                .getSessions()
                .then((res) => {
                    dispatch({ type: LiveManagementConstants.SESSIONS, payload: res.data });
                })
                .catch((err) => {
                    console.error('Error fetching sessions:', err);
                });
        sessionService
            .getSessionsFromZoom()
            .then((res) => {
                if (res.status == 'success') {
                    let ids = res.data.map((i) => i.id);
                    setZoomMeetingIds(new Set(ids));
                }
            })
            .catch(console.log);
        getSession();
        const interval = setInterval(() => {
            getSession();
        }, 1000 * 30);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        setCurrentSession(undefined);
        if (sessions) {
            setFilteredSessions(filterAndSortSessions(sessions));
        }
    }, [sessions, selectedTab]);

    const columns = (() => {
        switch (selectedTab) {
            case 'live':
                return [
                    {
                        name: 'Session Name',
                        selector: (row: SessionInterface) => row.internalName,
                        sortable: true,
                        cell: (row: any) => (
                            <div
                                data-tag="allowRowEvents"
                                style={{
                                    overflow: 'hidden',
                                    whiteSpace: 'wrap',
                                    textOverflow: 'ellipses',
                                }}
                            >
                                {' '}
                                {row.internalName}{' '}
                            </div>
                        ),
                    },
                    {
                        name: 'Node Ids',
                        sortable: true,
                        cell: (row: SessionInterface) =>  <HandleNodeIds ids={row?.nodeContentMapping}/>,
                    },
                    {
                        name: 'Start Time',
                        selector: (row: SessionInterface) => row.startTime,
                        sortable: true,
                        cell: (row: any) => (
                            <div
                                data-tag="allowRowEvents"
                                style={{
                                    overflow: 'hidden',
                                    whiteSpace: 'wrap',
                                    textOverflow: 'ellipses',
                                }}
                            >
                                {new Date(row.startTime).toLocaleString()}
                            </div>
                        ),
                    },
                    {
                        name: 'End Time',
                        selector: (row: SessionInterface) => row.endTime,
                        sortable: true,
                        cell: (row: any) => (
                            <div
                                data-tag="allowRowEvents"
                                style={{
                                    overflow: 'hidden',
                                    whiteSpace: 'wrap',
                                    textOverflow: 'ellipses',
                                }}
                            >
                                {new Date(row.endTime).toLocaleString()}{' '}
                            </div>
                        ),
                    },
                    {
                        name: 'Launch',
                        cell: (row: SessionInterface) =>
                            row.zoomLaunchUrl ? (
                                <Link
                                    to={`${row.zoomLaunchUrl}`}
                                    onClick={() => handleLaunch(row.zoomLaunchUrl, row.id)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                >
                                    <Button
                                        variant="default"
                                        size="sm"
                                        className="d-flex align-items-center gap-2 "
                                    >
                                        <FaExternalLinkAlt className="w-4 h-4" />
                                        Launch
                                    </Button>
                                </Link>
                            ) : (
                                <></>
                            ),
                    },
                ];
            case 'completed':
                return [
                    {
                        name: 'Session Name',
                        selector: (row: SessionInterface) => row.internalName,
                        sortable: true,
                        cell: (row: any) => (
                            <div
                                data-tag="allowRowEvents"
                                style={{
                                    overflow: 'hidden',
                                    whiteSpace: 'wrap',
                                    textOverflow: 'ellipses',
                                }}
                            >
                                {' '}
                                {row.internalName}{' '}
                            </div>
                        ),
                    },
                    {
                        name: 'Node Ids',
                        sortable: true,
                        cell: (row: SessionInterface) => <HandleNodeIds ids={row?.nodeContentMapping}/>,
                    },
                    {
                        name: 'Start Time',
                        selector: (row: SessionInterface) => istToReadable(row.startTime),
                        sortable: true,
                    },
                    {
                        name: 'End Time',
                        selector: (row: SessionInterface) => istToReadable(row.endTime),
                        sortable: true,
                    },
                    {
                        name: 'Student Count',
                        selector: (row: SessionInterface) => row.studentCount,
                        sortable: true,
                    },
                    {
                        name: 'Upload video/pdf',
                        cell: (row: SessionInterface) => (
                            <Button onClick={() => handleRecordings(row)}>Upload</Button>
                        ),
                    },
                ];

            case 'cancelled':
                return [
                    {
                        name: 'Session Name',
                        selector: (row: SessionInterface) => row.internalName,
                        sortable: true,
                        cell: (row: any) => (
                            <div
                                data-tag="allowRowEvents"
                                style={{
                                    overflow: 'hidden',
                                    whiteSpace: 'wrap',
                                    textOverflow: 'ellipses',
                                }}
                            >
                                {' '}
                                {row.internalName}{' '}
                            </div>
                        ),
                    },
                    {
                        name: 'Node Ids',
                        sortable: true,
                        cell: (row: SessionInterface) => <HandleNodeIds ids={row?.nodeContentMapping}/>,
                    },
                    {
                        name: 'Start Time',
                        selector: (row: SessionInterface) => istToReadable(row.startTime),
                        sortable: true,
                    },
                    {
                        name: 'End Time',
                        selector: (row: SessionInterface) => istToReadable(row.endTime),
                        sortable: true,
                    },
                ];

            case 'schdeuled':
            default:
                return [
                    {
                        name: 'Session Name',
                        selector: (row: SessionInterface) => row.internalName,
                        sortable: true,
                        cell: (row: any) => (
                            <div
                                data-tag="allowRowEvents"
                                style={{
                                    overflow: 'hidden',
                                    whiteSpace: 'wrap',
                                    textOverflow: 'ellipses',
                                }}
                            >
                                {row.internalName}
                            </div>
                        ),
                    },
                    {
                        name: 'Node Ids',
                        sortable: true,
                        cell: (row: any) => <HandleNodeIds ids={row?.nodeContentMapping}/>,
                    },
                    {
                        name: 'Start Time',
                        selector: (row: SessionInterface) => istToReadable(row.startTime),
                        sortable: true,
                    },
                    {
                        name: 'End Time',
                        selector: (row: SessionInterface) => istToReadable(row.endTime),
                        sortable: true,
                    },
                    {
                        name: 'Launch',
                        cell: (row: SessionInterface) =>
                            row.zoomLaunchUrl ? (
                                <Link
                                    to={`${row.zoomLaunchUrl}`}
                                    onClick={() => handleLaunch(row.zoomLaunchUrl, row.id)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                >
                                    <Button
                                        variant="default"
                                        size="sm"
                                        className="d-flex align-items-center gap-2 "
                                    >
                                        <FaExternalLinkAlt className="w-4 h-4" />
                                        Launch
                                    </Button>
                                </Link>
                            ) : (
                                <></>
                            ),
                    },
                ];
        }
    })();

    const handleLaunch = (url: string, id: number) => {
        const session = sessions.find((ses) => ses.id === id);
        dispatch({
            type: LiveManagementConstants.UPDATE_SESSION,
            payload: {
                ...session,
                status: 'LIVE',
            },
        });

        toast(`Launching session with ID: ${url}`);
    };

    const handleRecordings = (session: SessionInterface) => {
        setShowModal(true);
        setCurrentSession(session);
        toast(`opeining session with ID: ${session.sessionId}`);
    };

    const handleUpcomingSessionClick = (row: SessionInterface) => {
        setCurrentSession(row);
        setShowModal(true);
        dispatch({ type: LiveManagementConstants.CURRENT_SESSION_ID, payload: row.id });
        naivgate(location.pathname, { state: row });
        dispatch({ type: ModalsConstants.IS_Modal, payload: true });
    };

    return (
        <div className="mx-3">
            <h1>Live Dashboard</h1>
            <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                {['live', 'schdeuled', 'completed', 'cancelled'].map((tab) => (
                    <NavLink
                        key={tab}
                        to={`/contents/live-mangment/${tab}`}
                        style={({ isActive }) => ({
                            textDecoration: 'none',
                            padding: '10px 20px',
                            backgroundColor: isActive ? 'var(--primary-color)' : '#e0e0e0',
                            color: isActive ? 'white' : 'black',
                            borderRadius: '5px',
                        })}
                    >
                        {tab.replace('-', ' ').toUpperCase()}
                    </NavLink>
                ))}
                <Button className="ms-auto" onClick={() => setShowSessionModel(true)}>
                    Add Session
                </Button>
            </div>

            <DataTable
                // title={`Sessions - ${selectedTab}`}
                columns={columns}
                data={filteredSessions}
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100, 500, 1000]}
                onRowClicked={(row) => {
                    if (selectedTab === 'schdeuled') {
                        handleUpcomingSessionClick(row);
                    }
                }}
                conditionalRowStyles={[
                    {
                        when: (row) =>
                            zoomMeetingIds.size > 0 &&
                            !zoomMeetingIds.has(+row.sessionId) &&
                            (row.status == 'SCHEDULED' || row.status == 'LIVE'),
                        style: {
                            backgroundColor: '#FFCCCB',
                        },
                    },
                ]}
                customStyles={{
                    table: {
                        style: {
                            width: '90%',
                            margin: 'auto',
                        },
                    },
                }}
            />

            {selectedTab === 'schdeuled' && curentSession && (
                <FormModal
                    dialogClassName="modal-90w"
                    title="Update Session"
                    modalShow={isModal}
                    handleModalClose={() =>
                        dispatch({ type: ModalsConstants.IS_Modal, payload: false })
                    }
                    backdrop="static"
                    size="xl"
                >
                    <Sessions key={5} />
                </FormModal>
            )}

            {selectedTab === 'completed' && curentSession?.sessionId && (
                <FormModal
                    dialogClassName="modal-90w"
                    title="Add Pdf or Recodings"
                    modalShow={showModal}
                    handleModalClose={() => setShowModal(false)}
                    backdrop="static"
                    size="xl"
                >
                    <LiveManagmentContent
                        closeModal={() => setShowModal(false)}
                        sessionId={curentSession.sessionId}
                    />
                </FormModal>
            )}
            <Modal show={showSessionModel} onHide={() => setShowSessionModel(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateSessionModel onSuccess={() => setShowSessionModel(false)} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowSessionModel(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default LiveManagement;
