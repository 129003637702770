import React from "react";
import { QuizAttemptItem } from "../../types/quizTypes";
import { Table } from "react-bootstrap";
import formatDuration from "../helpers/timeFormat";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
function formatTimestamp(timestamp: number) {
  const date = new Date(timestamp);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const formattedMonth = months[monthIndex];
  const formattedDate = `${
    day < 10 ? "0" : ""
  }${day} ${formattedMonth} ${year} ${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  return formattedDate;
}
function calculateTimeTaken(startTime: string, endTime: string) {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const diffInSeconds = Math.floor((end.getTime() - start.getTime()) / 1000); // Convert difference to seconds
  return diffInSeconds;
}

function TestInsightTable({ quizData }: {quizData: QuizAttemptItem[]}) {
  return (
    <div>
    {quizData && quizData?.length>0 ? 
        <div className="row justify-content-center mb-0 ">
          <div className="col-12 col-lg-12 col-xl-12 mb-8 mb-lg-0">
            <h4 className="my-3">Test Taken </h4>
            <Table striped bordered hover>
              <thead>
                <tr className="accordion">
                  <th scope="col">
                    <strong>Quiz Name</strong>
                  </th>
               
                  <th scope="col">
                    <strong>Start Time</strong>
                  </th>
                
                 
                  <th scope="col">
                    <strong>Total Marks</strong>
                  </th>
                  <th scope="col">
                    <strong>Time Taken</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {quizData?.map((quiz: any, i: number) => (
                  <tr key={i} className={`accordion`}>
                    <td>{quiz.quiz.internalName}</td>
                   
                    <td>{formatTimestamp(quiz.startTime)}</td>
                   
                    <td>{quiz.marksScored}/{quiz.quizTotalMarks}</td>
                    <td>
                      {formatDuration(calculateTimeTaken(quiz.startTime, quiz.endTime))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
     : 'No Quiz Attemped' }
    </div>
  );
}

export default TestInsightTable;
