import React, { memo, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { formatDateFromTimestamp, secToHHMMSS } from '../../utils/helpers';
import toast from 'react-hot-toast';
import { useLocation, useParams } from 'react-router-dom';
import { axiosClient } from '../../services/axiosClient';
import contentUsageServices from '../../services/content-usage-services';
import { GroupedByDateContent, Level1Content, Level2Content, UserActivity } from '../../types/contentUsageTypes';
import Level1Accordion from './InsightsAccordionItem';
import { ENDPOINT_HOST, STUDENT_ENDPOINT_HOST } from '../../config';
import TestInsightTable from '../StudentReport/TestInsightTable';
import { QuizAttemptItem } from '../../types/quizTypes';


const Insights = () => {
    const params = useParams()
    const state = useLocation()
    const studentId = params?.studentId ? params?.studentId : state.state?.studentId

    const [usageTo, setUsageTo] = useState(new Date().getTime() / 1000);
    const [usageFrom, setUsageFrom] = useState(new Date(usageTo - 7 * 24 * 60 * 60).getTime());
    const [userUsageData, setUserUsageData] = useState<GroupedByDateContent[]>([]);
    const [filteredUsage, setFilteredUsage] = useState(0);
    const [userTotalUsage, setUserTotalUsage] = useState(0);
    const [quizData, setQuizData] = useState<QuizAttemptItem[]>([]);
    const { pathname } = useLocation();

    const getUsage = async (studentId: number) => {
        const usageToDate = new Date(usageTo * 1000).toISOString().split('T')[0];
        const usageFromDate = new Date(usageFrom * 1000).toISOString().split('T')[0];

        const query = `from=${usageFromDate}&to=${usageToDate}`;


        try {
            let usage;

            let quizdata;
            if (pathname.includes('/student/enrolled-students') && !studentId) {
                // toast.error('You need to provide userId');
                return;
            } 
            // for admin panel insight tab and admin panel open page
            if (studentId) {
                const data = await axiosClient.get(`${ENDPOINT_HOST}/general/content/content-usage/${studentId}?${query}`);
                usage = data.data;
                const response = await axiosClient.get(`${ENDPOINT_HOST}/general/attempts/${studentId}?${query}`)
                quizdata = response.data.data;
            } 
            // for Mobile app.
            else {
                const data = await axiosClient.get(`${STUDENT_ENDPOINT_HOST}/content/content-usage?${query}`)
                usage = data.data
                const response = await axiosClient.get(`${STUDENT_ENDPOINT_HOST}/quiz/user-attempts?${query}`)
                quizdata = response.data.data;
            }
           
            if (usage.status === 'success') {
                const usageData = usage.data.usage;
                const durInSecSum = usageData.reduce(
                    (acc: number, curr: any) => Number(acc) + Number(curr.durInSec),
                    0
                );
                setQuizData(quizdata)
                setFilteredUsage(+durInSecSum);
                setUserUsageData(groupData(usageData));
                setUserTotalUsage(usage.data?.totalUsage ?? 0);
            } else {
                throw new Error(usage?.errors.join(', '));
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        // if (studentId) {
            getUsage(+studentId);
        // }
    }, [studentId]);

    function groupData(data: UserActivity[]): GroupedByDateContent[] {
        return data.reduce<GroupedByDateContent[]>((acc, item) => {
            // Find or create level 1 entry
            let dateEntry = acc.find((entry) => entry.date === item.date);
            if (!dateEntry) {
                dateEntry = { date: item.date, level1Content: [], time: 0 }; // Initialize time to 0
                acc.push(dateEntry);
            }

            // Accumulate the time for the same date
            dateEntry.time += Number(item.durInSec);

            let level1 = dateEntry.level1Content.find((l1) => l1.level1Id === item.level1Id);
            if (!level1) {
                level1 = { level1Id: item.level1Id, level1Name: item.level1Name, content: [] };
                dateEntry.level1Content.push(level1);
            }

            // Find or create level 2 entry
            let level2 = level1.content.find(
                (l2): l2 is Level2Content => 'level2Id' in l2 && l2.level2Id === item.level2Id
            );

            if (!level2 && item.level2Id !== null) {
                level2 = {
                    level2Id: item.level2Id,
                    level2Name: item.level2Name ?? '',
                    content: [],
                };
                level1.content.push(level2);
            }

            // Add content if level 2 exists or if level 2 is null
            if (level2) {
                level2.content.push({
                    contentId: item.contentId,
                    contentName: item.contentName,
                    date: item.date,
                    durInSec: item.durInSec,
                    assetId: item.assetId,
                    assetType: item.assetType,
                    parentAssetId: item.parentAssetId,
                    parentAssetType: item.parentAssetType,
                });
            } else if (item.level2Id === null) {
                level1.content.push({
                    contentId: item.contentId,
                    contentName: item.contentName,
                    date: item.date,
                    durInSec: item.durInSec,
                    assetId: item.assetId,
                    assetType: item.assetType,
                    parentAssetId: item.parentAssetId,
                    parentAssetType: item.parentAssetType,
                });
            }

            return acc;
        }, []);
    }


    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const timestamp = new Date(event.target.value).getTime() / 1000; // Convert to Unix timestamp
        if (name === 'usageFrom') setUsageFrom(timestamp);
        else setUsageTo(timestamp);
    };

    const handleDateFormatNumberTostring = (date: number) => {
        return new Date(date * 1000).toISOString().split('T')[0];
    };

    if ((!studentId && pathname.includes('/student/enrolled-students'))) {
        return <>Student Not found. Er5412564</>
    }
    
    return (
        <div className="container insights p-0">
            <div className="content-usage-container">
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        getUsage(+studentId);
                    }}
                >
                    <Row md="auto" className="d-flex align-items-center">
                        <Row md="auto" className="pt-3 d-flex align-items-center">
                            <Col>
                                <Form.Group className="my-2 my-md-2">
                                    <Row>
                                        <Col xs={4} sm={4} md={4}>
                                            <Form.Label column="sm" style={{ marginRight: 15 }}>
                                                From:
                                            </Form.Label>
                                        </Col>
                                        <Col xs={8} sm={8} md={8}>
                                            <Form.Control
                                                size="sm"
                                                type="date"
                                                name="usageFrom"
                                                defaultValue={handleDateFormatNumberTostring(
                                                    usageFrom
                                                )}
                                                onChange={handleDateChange}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Row>
                                        <Col xs={4} sm={4} md={4}>
                                            <Form.Label column="sm" style={{ marginRight: 15 }}>
                                                To:
                                            </Form.Label>
                                        </Col>
                                        <Col xs={8} sm={8} md={8}>
                                            <Form.Control
                                                size="sm"
                                                type="date"
                                                name="usageTo"
                                                defaultValue={handleDateFormatNumberTostring(
                                                    usageTo
                                                )}
                                                onChange={handleDateChange}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row md="auto" className="pt-3 d-flex justify-content-end">
                            <Button
                                size="sm"
                                variant="primary"
                                type="submit"
                                style={{ width: '60px' }}
                            >
                                Show
                            </Button>
                        </Row>
                    </Row>
                </Form>

                <div className="container py-4">
                    <div className="row gy-4 gx-4 justify-content-center">
                        <div className="col-12 col-md-5">
                            <Card className="h-100 shadow border-0 rounded-3">
                                <Card.Body className="d-flex flex-column align-items-center text-center">
                                    <Card.Title className="fs-4 fw-semibold mb-3">
                                        Total Time Spent
                                    </Card.Title>
                                    <Card.Subtitle className="fs-3 text-primary">
                                        {secToHHMMSS(userTotalUsage, false)}
                                    </Card.Subtitle>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-12 col-md-5">
                            <Card className="h-100 shadow border-0 rounded-3">
                                <Card.Body className="d-flex flex-column align-items-center text-center">
                                    <Card.Title className="fs-4 fw-semibold mb-3 text-center">
                                        Time Spent
                                        <div className="d-block mt-1" style={{
                                            fontSize: '1rem'
                                        }}>
                                            From <strong>{formatDateFromTimestamp(usageFrom)} </strong> to <strong>{formatDateFromTimestamp(usageTo)}</strong>
                                        </div>
                                    </Card.Title>

                                    <Card.Subtitle className="fs-3 text-primary">
                                        {secToHHMMSS(filteredUsage, false)}
                                    </Card.Subtitle>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>

                {/* <div>
          {
            userUsageData.length ?
              userUsageData?.map((usage, i: number) => (
                <InsightsAccordionItem key={i} data={usage} keyName="chapter" />
              ))
              :
              <></>
          }
        </div> */}

                <Level1Accordion data={userUsageData} />
            </div>

            <TestInsightTable quizData={quizData} />
        </div>
    );
};

export default memo(Insights);