import { FormEvent, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BACKEND_URLS, FRONTEND_URLS } from '../../config';
import { ContentConstants } from '../../Redux/content/content-constants';
import { useAppDispatch, useAppSelector } from '../../Redux/hooks';
import { LiveManagementConstants } from '../../Redux/live-mangment/live-mangment-constant';
import { ModalsConstants } from '../../Redux/models/models-constants';
import { axiosClient } from '../../services/axiosClient';
import { AssetData } from '../../types/ApiTypes';
import { AssetType, ZoomSession } from '../../types/types';
import { convertISTtoUTC, convertUTCtoIST } from '../../utils/helpers';
import { SessionInterface } from '../../types/LiveTypes';

interface zoomUsersInterface {
    id: number;
    hostId: string;
    name: string;
    userEmail: string;
    accountId: string;
    clientId: string;
    clientSecret: string;
    webhookSecret: string;
}

const Sessions = () => {
    const { state, pathname }: { state: AssetData | undefined; pathname: string } = useLocation();
    const { contentId } = useParams();

    const [zoomUser, setZoomUser] = useState<zoomUsersInterface | undefined>(); // to control Zoom User selection
    const [zoomUsers, setZoomUsers] = useState<zoomUsersInterface[]>([]);
    const [zoomUserId, setZoomUserId] = useState<number>();

    const [showZoomFields, setShowZoomFields] = useState<'auto' | 'manual'>('auto'); // show/hide Zoom fields based on selection
    const [platform, setPlatofrm] = useState('ZOOM');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isRecording, setIsRecording] = useState(true);
    const [sessionId, setSessionId] = useState('');
    const [internalName, setInternalName] = useState('');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { nodeId } = useParams();
    const [loading, setLoading] = useState(false);

    const sessions = useAppSelector((state) => state.liveMangment?.sessions) as SessionInterface[];
    const currentSessionId = useAppSelector((state) => state.liveMangment?.currentSessionId);

    const session = sessions.find((ses) => ses.id === currentSessionId);

    const selectedChapter = useAppSelector((state) => state?.ContentReducer?.selectedChapter) as {
        level2Id: number;
        level2Name: number;
    };

    const handleZoomUserChange = (e: any) => {
        const value = e.target.value;
        if (value === 'manual') {
            setShowZoomFields('manual');
        } else {
            setShowZoomFields('auto');
        }
    };

    const validateZoomFields = (data: any) => {
        setLoading(true);
        const requiredFields =
            showZoomFields === 'manual'
                ? ['sessionId']
                : ['internalName', 'startTime', 'endTime', 'hostId'];

        for (const field of requiredFields) {
            if (!data[field]) {
                toast.error(`Error: ${field} is required.`);
                return true;
            }
        }
        return false;
    };

    async function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setLoading(true);
        // Check if endTime is greater than startTime
        if ((new Date(endDate)) <= (new Date(startDate))) {
          toast.error("The end time cannot be earlier than the start time 🫡.");
        setLoading(false)
          return false; // Validation failed
        }
        if (currentSessionId) {
            try {
                const update = {
                    internalName,
                    sessionId: session?.sessionId,
                    password: session?.password,
                    startTime: startDate,
                    endTime: endDate,
                    recording: isRecording,
                    // zoomUserEmail: "me", // Optional, if needed
                };



                const response = await axiosClient.put(
                    `${BACKEND_URLS.CREATE_SESSION}/${session?.id}`,
                    update
                );

                if (response.status >= 200 && response.status < 300) {
                    toast.success('Session updated successfully.');
                    const data = response.data.data;
                    dispatch({ type: ModalsConstants.IS_Modal, payload: false });
                    if (pathname.includes('live-mangment')) {
                        dispatch({
                            type: LiveManagementConstants.UPDATE_SESSION,
                            payload: response.data.data,
                        });
                    } else {
                        dispatch({
                            type: ContentConstants.EDIT_SESSION,
                            payload: { id: contentId, assetData: data },
                        });
                    }
                } else {
                    return toast.error(`Unexpected response:  ${response.data.message}`);
                }
            } catch (error: any) {
                if (error.response) {
                    setLoading(false);
                    toast.error(
                        `Failed to update session. Server responded with: ${
                            error.response.data.message || 'Unknown error'
                        }`
                    );
                    return;
                } else if (error.request) {
                    setLoading(false);
                    toast.error(
                        'No response from the server. Please check your network connection.'
                    );
                    return;
                } else {
                    setLoading(false);
                    toast.error(`Unexpected error: ${error.message}`);
                    return;
                }
            }
            // finally {
            //     dispatch({ type: LiveManagementConstants.CURRENT_SESSION_ID, payload: null })
            // }
        } else {
            const data =
                showZoomFields == 'manual'
                    ? { sessionId }
                    : {
                          internalName: internalName,
                          startTime: startDate,
                          endTime: endDate,
                          recording: isRecording,
                          hostId: zoomUser?.hostId,
                      };
            try {
                if (validateZoomFields(data)) {
                    setLoading(false);
                    return;
                }
                const sessionCreated = await axiosClient.post(BACKEND_URLS.CREATE_SESSION, data);
                const sessionCreatedData: ZoomSession = sessionCreated.data.data;

                const content = {
                    isNew: true,
                    id: -1,
                    name: sessionCreatedData.internalName,
                    assetType: AssetType.SESSION,
                    assetId: sessionCreatedData.id,
                    type: '',
                    level2Id: selectedChapter.level2Id,
                    level2Name: selectedChapter.level2Name,
                    level3Id: null,
                    parentAssetType: 'null',
                    parentAssetId: 0,
                    isFree: false,
                    createdOn: '',
                    assetData: sessionCreatedData,
                };

                dispatch({ type: ContentConstants.ADD_CONTENT, payload: content });
                dispatch({ type: ModalsConstants.IS_Modal, payload: false });
                navigate(
                    FRONTEND_URLS.CONTENTS.EDIT_NODE +
                        `/${nodeId}` +
                        '/' +
                        FRONTEND_URLS.CONTENTS.ASSET +
                        '/' +
                        content.id
                );
            } catch (error: any) {
                setLoading(false);
                toast.error(error.response.data.message);
            }
            // finally {
            //     dispatch({ type: LiveManagementConstants.CURRENT_SESSION_ID, payload: null })
            // }
        }
    }
    const [isLoading, setIsLoading] = useState(true); // New loading state

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axiosClient.get(BACKEND_URLS.ZOOM_USERS);
                const users = res.data.data;
                setZoomUsers(users);

                if (currentSessionId) {
                    setInternalName(session?.internalName ?? '');
                    setZoomUserId(session?.zoomInstanceId);
                    setEndDate(session?.endTime ?? '');
                    setStartDate(session?.startTime ?? '');
                    setIsRecording(session?.isRecorded ?? true);
                } else {
                    setZoomUser(users[0]);
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
            } finally {
                setIsLoading(false); // Ensure loading state is updated
            }
        };

        fetchData();
    }, []);

    if (isLoading) return <div>Loading...</div>; // Render a loader until data is ready

    return (
        <Form onSubmit={handleSubmit} className="p-2">
            <Form.Group controlId="platformSelect" className="mb-3 row align-items-center">
                <Form.Label className="col-sm-3 col-form-label">Platform</Form.Label>
                <div className="col-sm-9">
                    <Form.Control
                        as="select"
                        disabled
                        value={platform}
                        onChange={(e) => setPlatofrm(e.target.value)}
                    >
                        <option>Zoom</option>
                        <option>Google Meet</option>
                        <option>Microsoft Teams</option>
                    </Form.Control>
                </div>
            </Form.Group>

            <Form.Group controlId="zoomUserSelect" className="mb-3 row align-items-center">
                <Form.Label className="col-sm-3 col-form-label">Zoom User</Form.Label>
                <div className="col-sm-9">
                    <Form.Control
                        as="select"
                        value={showZoomFields}
                        onChange={handleZoomUserChange}
                        disabled={!!state}
                    >
                        <option value="auto">Auto</option>
                        <option value="manual">Manual</option>
                    </Form.Control>
                </div>
            </Form.Group>

            {showZoomFields == 'manual' ? (
                <Form.Group controlId="zoomId" className="mb-3 row align-items-center">
                    <Form.Label className="col-sm-3 col-form-label">Session ID</Form.Label>
                    <div className="col-sm-9">
                        <Form.Control
                            type="text"
                            placeholder="123456"
                            value={sessionId}
                            onChange={(e) => setSessionId(e.target.value)}
                        />
                    </div>
                </Form.Group>
            ) : (
                <>
                    <Form.Group controlId="startDateTime" className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">Session Name</Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                type="text"
                                value={internalName}
                                onChange={(e) => setInternalName(e.target.value)}
                                placeholder="write internal name here..."
                            />
                        </div>
                    </Form.Group>

                    <Form.Group controlId="zoomUserSelect" className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">Users</Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                as="select"
                                value={zoomUserId}
                                onChange={(e) => {
                                    const selectedUserId = e.target.value;
                                    const selectedUser = zoomUsers.find(
                                        (user) => `${user.id}` == selectedUserId
                                    );
                                    setZoomUser(selectedUser); // Update the state with the selected user
                                    setZoomUserId(+selectedUserId);
                                }}
                                disabled={!!state}
                            >
                                {zoomUsers.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.name} ({user.userEmail})
                                    </option>
                                ))}
                            </Form.Control>
                        </div>
                    </Form.Group>

                    <Form.Group controlId="startDateTime" className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">Start Date Time</Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                type="datetime-local"
                                value={startDate ? convertUTCtoIST(startDate) : ''}
                                onChange={(e) => {
                                    setStartDate(convertISTtoUTC(e.target.value));
                                }}
                            />
                        </div>
                    </Form.Group>

                    <Form.Group controlId="endDateTime" className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">End Date Time</Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                type="datetime-local"
                                value={endDate ? convertUTCtoIST(endDate) : ''}
                                onChange={(e) => setEndDate(convertISTtoUTC(e.target.value))}
                            />
                        </div>
                    </Form.Group>

                    <Form.Group controlId="saveRecording" className="mb-3 row align-items-center">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-9">
                            <Form.Check
                                type="checkbox"
                                label="Save Recording"
                                onChange={(e) => setIsRecording(e.target.checked)}
                                checked={isRecording}
                            />
                        </div>
                    </Form.Group>
                </>
            )}
            <Button variant="primary" type="submit" disabled={loading}>
                {session ? 'Edit' : 'Add'}
            </Button>
        </Form>
    );
};

export default Sessions;
