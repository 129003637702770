import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import { axiosClient } from "../../services/axiosClient";
import { BACKEND_URLS, FRONTEND_URLS } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { ContentConstants } from "../../Redux/content/content-constants";
import { ModalsConstants } from "../../Redux/models/models-constants";
import { ChapterType, LevelInterface } from "../../types/ApiTypes";
import toast from "react-hot-toast";
import nodeServices from "../../services/node.services";
import { NodeContentType } from "../../types/node.types";
import * as yup from 'yup';
import { useNavigate, useParams } from "react-router-dom";
import { transformAssetData } from "../../utils/helpers";


const ImportVideo = () => {
    const schema = yup.object().shape({
        search: yup.string(),
        subject: yup.string(),
        chapter: yup.string(),
        contentId: yup.string()
    });

    const subjectData = useSelector<any>(state => state.ContentReducer.subject) as LevelInterface[];
    const selectedChapter = useSelector<any>(state => state.ContentReducer.selectedChapter) as { level2Id: number, level2Name: number };
    const subjectId = useSelector<any>(state => state.ContentReducer.selectedSubjectId) as number

    const [chapterData, setChapterData] = useState<ChapterType[]>([]);
    const [data, setData] = useState<NodeContentType[]>([]);
    const [selectedSubject, setSelectedSubject] = useState<string>('');
    const [selectedContent, setSelectedContent] = useState<any>()
    const [selectedRow, setSelectedRow] = useState<number | null>(null);

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { nodeId } = useParams()

    const handleAddContent = () => {
        const data = transformAssetData(selectedContent)
        let contentId = -1
        if  (selectedContent.level2Id == selectedChapter.level2Id) {
            contentId = selectedContent.contentId
            dispatch({ type: ContentConstants.ADD_CONTENT, payload: { ...data, "isNew": true, isImported: true, id: selectedContent.contentId, level2Id: selectedChapter.level2Id, level2Name: selectedChapter.level2Name, newLevel2Id: selectedContent.level2Id, newLevel2Name: selectedContent.level2Name} })
        }
        else {
            contentId = -1
            dispatch({ type: ContentConstants.ADD_CONTENT, payload: { ...data, "isNew": true, isImported: true, level2Id: selectedChapter.level2Id, level2Name: selectedChapter.level2Name, id: -1, newLevel2Id: selectedContent.level2Id, newLevel2Name: selectedContent.level2Name} })
        }
        dispatch({ type: ModalsConstants.IS_Modal, payload: false })
        navigate(FRONTEND_URLS.CONTENTS.EDIT_NODE + `/${nodeId}` + '/' + FRONTEND_URLS.CONTENTS.ASSET + '/' + contentId)
    }

    const handleSubjectChange = async (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const subjectId = e.target.value;
        setSelectedSubject(subjectId);

        if (subjectId) {
            try {
                const { data } = await axiosClient.get(
                    BACKEND_URLS.LEVEL + 2 + `?level1Id=${subjectId}`
                );
                setChapterData(data.data.level);
            } catch (error: any) {
                toast.error(error.message);
            }
        } else {
            setChapterData([]);
        }
    };

    const handleFilter = async (values: {
        search?: string;
        subject?: string;
        chapter?: string;
        contentId?: string
    }) => {
        setLoading(true);

        try {
            const {data} = await nodeServices.getContentType({
                assetType:'video',
                name: values.search,
                level1Id: values.subject,
                level2Id: values.chapter,
                contentId: values.contentId
            })
            setData(data);
            setLoading(false);
        } catch (error: any) {
            toast.error(error.message);
            setLoading(false);
        }
    };

    const columns: TableColumn<NodeContentType>[] = [
        {
            name: 'Thumbnail',
            cell: (row) => <div
                data-tag="allowRowEvents"
                style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            > <img src={row.thumbnailHr ?? row.thumbnailLr} style={{ width: '100px' }} /> </div>
        },
        {
            name: 'Content Id',
            selector: (row) => row.contentId,
            cell: (row) => <div
                data-tag="allowRowEvents"
                style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            > {row.contentId} </div>
        },
        {
            name: 'Name',
            selector: (row) => row.internalName,
            cell: (row) => <div
                data-tag="allowRowEvents"
                style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            > {row.internalName} </div>
        },
        {
            name: 'Dur. (min)',
            selector: (row) => row.durInSec,
            cell: (row) => <div
                data-tag="allowRowEvents"
                style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            > {row.durInSec} </div>
        },
        {
            name: 'Upload Date',
            selector: (row) => row?.assetCreatedOn?.split('T')[0] ?? '',
            cell: (row) => <div
                data-tag="allowRowEvents"
                style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            > {row?.assetCreatedOn?.split('T')[0] ?? ''} </div>
        },
        {
            name: 'Chapter',
            selector: (row) => row?.level2InternalName ?? '',
            cell: (row) => <div
                data-tag="allowRowEvents"
                style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            > {row?.level2InternalName ?? ''}</div>
        },
    ]


    const handleRowSelected = ({ selectedRows }: { selectedRows: NodeContentType[] }) => {
        setSelectedRow((selectedRows[0] && selectedRows[0].assetId) ? selectedRows[0].assetId : null);
        setSelectedContent(selectedRows[0])
    };

    const conditionalRowStyles = [
        {
            when: (row: any) => row.id === selectedRow,
            style: {
                backgroundColor: '#d4edda',
                color: 'black',
            },
        },
    ];

    return (
        <>
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    handleFilter(values);
                }}
                initialValues={{
                    search: '',
                    subject: '',
                    chapter: '',
                    contentId: ''
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit} className='d-flex flex-wrap justify-content-between p-2'>
                        {/* Search input */}

                        <Form.Group controlId="contentId" className="m-1 mb-2 col-md-3">
                          <Form.Control
                            type="text"
                            placeholder="Search By content id"
                            name="contentId"
                            value={values.contentId}
                            onChange={handleChange}
                            isInvalid={touched.contentId && !!errors.contentId}
                          />
                          <Form.Control.Feedback type="invalid" className="text-danger">
                            {errors.contentId}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="validationFormik04" className="m-1 mb-2 col-md-3">
                            <Form.Control
                                type="text"
                                placeholder="Search here..."
                                name="search"
                                value={values.search}
                                onChange={handleChange}
                                isInvalid={touched.search && !!errors.search}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger">
                                {errors.search}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="m-1 mb-2 col-md-3">
                            <Form.Select
                                name='subject'
                                onChange={(e) => {
                                    handleChange(e);
                                    handleSubjectChange(e);
                                }}
                                value={values.subject}
                                isInvalid={touched.subject && !!errors.subject}
                            >
                                <option value="">Select a subject</option>
                                {subjectData.map((subject) => (
                                    <option key={subject.id} value={subject.id}>
                                        {subject.internalName}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid" className="text-danger">
                                {errors.subject}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {chapterData.length > 0 && (
                            <Form.Group className="m-1 mb-2 col-md-3">
                                <Form.Select
                                    name='chapter'
                                    onChange={handleChange}
                                    value={values.chapter}
                                    isInvalid={touched.chapter && !!errors.chapter}
                                >
                                    <option value="">Select a Chapter</option>
                                    {chapterData.map((chapter) => (
                                        <option key={chapter.id} value={chapter.id}>
                                            {chapter.internalName}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" className="text-danger">
                                    {errors.chapter}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}

                        <Button type='submit' variant="primary" className="m-1 mb-2 col-md-3" disabled={loading}>{loading ? `Loading...` :`Filter`}</Button>
                    </Form>
                )}
            </Formik>
            <hr />
            <DataTable
                columns={columns}
                data={data}
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100, 500, 1000]}
                selectableRowsSingle
                onSelectedRowsChange={handleRowSelected}
                selectableRows
                conditionalRowStyles={conditionalRowStyles}
                customStyles={{
                    header: {
                        style: {
                            resize: "horizontal"
                        }
                    }
                }}
                expandOnRowClicked
                highlightOnHover
                pointerOnHover
            />

            {/* <SpecialButton disabled={!selectedContent} onClick={handleAddContent} text='Add' variant='btn-13' /> */}
            <Button disabled={!selectedContent} onClick={handleAddContent} variant="success" className="mt-3" >Add</Button>
        </>
    );
};

export default ImportVideo