import { useState } from 'react'
import DataTable from 'react-data-table-component';
import { HardwareInterface, HardwareApiInterface } from '../../types/ApiTypes';
import { BACKEND_URLS } from '../../config';
import { axiosClient } from '../../services/axiosClient';
import { useLoaderData } from 'react-router-dom';
import MainBtn from '../../components/btn/main-btn';
import ServiesModal from '../../components/modals/create-service-modal';
import axios from 'axios';
import HardwareModal from '../../components/modals/create-hardware-modal';
import toast from 'react-hot-toast';



export async function loader(): Promise<HardwareApiInterface> {
    const data = await axiosClient.get(BACKEND_URLS.HARDWARS);
    return data.data;
}


function Hardware() {
    const { data } = useLoaderData() as HardwareApiInterface;
    const [hardwareData, setHardwareData] = useState<HardwareInterface[]>(data.hardwares)
    const [show, setShow] = useState<boolean>(false)
    const [id, setId] = useState<number | undefined>(undefined)
    const [name, setName] = useState<string | undefined>(undefined)
    const [minAmount, setMinAmount] = useState<number | undefined>(undefined)


    const handleShow = () => setShow(true);

    const handleClick = (row: HardwareInterface) => {
        setId(row.id)
        setName(row.name)
        setMinAmount(row.minAmount)
        setShow(true);
    };

    const hardwareColumns = [
        {
            name: "Hardware Name",
            selector: (row: HardwareInterface) => row.name,
            cell: (row: any) => <div
                data-tag="allowRowEvents"
                style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            > {row.name} </div>
        },
        {
            name: "Min Amount",
            selector: (row: HardwareInterface) => row.minAmount,
            cell: (row: any) => <div
                data-tag="allowRowEvents"
                style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            > {row.minAmount} </div>
        },
        {
            name: "Created By",
            selector: (row: HardwareInterface) => row.createdBy?.name || '',
            cell: (row: any) => <div
                data-tag="allowRowEvents"
                style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            > {row.createdBy?.name || ''} </div>
        },
        {
            name: "Created On",
            selector: (row: HardwareInterface) => row.createdOn.split("T")[0],
            cell: (row: any) => <div
                data-tag="allowRowEvents"
                style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            > {row.createdOn.split("T")[0]} </div>
        },
        {
            name: 'Action',
            cell: (row: HardwareInterface) => (
                <button className="btn btn-primary" onClick={() => handleClick(row)}>
                    Edit
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handleSubmit = async ({ name, minAmount }: { name: string; minAmount: number }) => {
        try {

            if (id) {
                const res = await axiosClient.put(BACKEND_URLS.HARDWARS + `/` + id, {
                    name: name,
                    minAmount
                });

                if (res.status === 200) {
                    toast.success("hardware Item updated successfully!");
                } else {
                    toast.error(`Failed to create hardware. Status: ${res.status}`);
                }
            }
            else {
                const res = await axiosClient.post(BACKEND_URLS.HARDWARS, {
                    name,
                    minAmount
                });

                if (res.status === 201) {
                    toast.success("HARDWARS created successfully!");
                } else {
                    toast.error(`Failed to create hardware. Status: ${res.status}`);
                }
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                toast.error(`Error: ${error.response?.data?.message || "Failed to create hardware."}`);
            } else {
                toast.error("An unexpected error occurred.");
            }
        }
        finally {
            setShow(false)
            setId(undefined)
            setName(undefined)
            setMinAmount(undefined)
            const res = await axiosClient.get(BACKEND_URLS.HARDWARS)
            setHardwareData(res.data.data.hardwares)
        }
    };


    return (
        <div className='mx-4'>
            <h2>
                Hardware
            </h2>

            <DataTable
                columns={hardwareColumns}
                data={hardwareData}  // Make sure to pass the correct data array here
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100, 500, 1000]}
                customStyles={{
                    table: {
                        style: {
                            width: '90%',
                            margin: 'auto'
                        }
                    }
                }}
            />

            {
                show
                &&
                <HardwareModal handleSubmit={handleSubmit} setShow={setShow} show={show} key='Services' id={id} hardwareName={name} hardwareMinAmount={minAmount} />
            }

            <MainBtn
                message="create service"
                onClick={handleShow}
            />

        </div>
    )
}

export default Hardware