import { TicketResponse } from '../../../types/DoubtTypes';
import DoubtTicketHeader from '../../../components/Doubts/DoubtTicketHeader';
import { Modal } from 'react-bootstrap';
import SingleDoubt from '../../../components/Doubts/SingleDoubt/SingleDoubt';
import { useEffect, useMemo, useState } from 'react';
import DoubtService from '../../../services/ApiServices/doubt-service';
import date from '../../../utils/helpers/date';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useLoaderData } from 'react-router-dom';
import { User } from '../../../types/user-profille-types';
import userService from '../../../services/user-service';

export async function loader() {
    const response = await DoubtService.getAllTickets();
    const tickets = response.data.tickets;
    const userId = response.data.userId;
    return { tickets, userId };
}

const columns: TableColumn<TicketResponse>[] = [
    {
        name: 'Student Details',
        cell: (row) => {
            const maskedPhone = row.user.username.replace(/(\d{3})\d+(\d{3})/, '$1XXXX$2');
            return (
                <div>
                    <span>{row.user.name}</span>
                    <br />
                    <span>{maskedPhone}</span>
                    <br />
                    <span>{'EMAIL'}</span>
                </div>
            );
        },
        sortable: true,
    },
    {
        name: 'Set',
        sortable: true,
        cell: (row: any) => <div
            data-tag="allowRowEvents"
            style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
        > {row.set} </div>,
    },
    {
        name: 'Assigned To',
        sortable: true,
        cell: (row: any) => <div
            data-tag="allowRowEvents"
            style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
        > {row.assignedTo} </div>,
    },
    {
        name: 'Department',
        sortable: true,
        cell: (row: any) => <div
            data-tag="allowRowEvents"
            style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
        > {row.ticketType} </div>,
    },
    {
        name: 'Created on',
        cell: (row: any) => {
            const timeDate = date.timeStampToStr(row.createdOn, false);
            return (
                <div
                    data-tag="allowRowEvents"
                    style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
                > {`${timeDate.date} ${timeDate.time}`} </div>
            )
        },
        sortable: true,
    },
    {
        name: 'Unread messages',
        cell: (row: any) => <div
            data-tag="allowRowEvents"
            style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
        > {row.unread} </div>,
    },
    {
        name: 'Comment',
        sortable: true,
        cell: (row: any) => <div
            data-tag="allowRowEvents"
            style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
        > {row.questionText} </div>,
    },
    {
        name: 'Status',
        sortable: true,
        cell: (row: any) => <div
            data-tag="allowRowEvents"
            style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
        > {row.status} </div>,
    },
];

function Doubts() {
    const [show, setShow] = useState(false);
    const { tickets: allTickets, userId } = useLoaderData() as {
        tickets: TicketResponse[];
        userId: number;
    };
    const [selectedDoubt, setSelectedDoubt] = useState<TicketResponse | null>(null);
    const [filter, setFilter] = useState('');
    const [type, setType] = useState('ALL');
    const [users, setUsers] = useState<User[]>([]);

    const handleFilterApply = (value: any) => {
        setFilter(value);
    };

    const handleTypeChange = (e: any) => {
        setType(e);
    };

    const filteredData = useMemo(() => {
        return allTickets.filter((doubt) => {
            return (
                (type === 'ALL' || doubt.status === type) &&
                (filter ? doubt.questionText.toLowerCase().includes(filter.toLowerCase()) : true)
            );
        });
    }, [type, filter, allTickets]);

    const handleRowClick = (row: TicketResponse) => {
        setSelectedDoubt(row);
        setShow(true);
    };

    const headerTypeData = () => {
        let ALL = 0;
        let NEW = 0;
        let CLOSED = 0;
        let SOLVED = 0;

        for (let d of allTickets) {
            if (d.status === 'NEW') {
                NEW += 1;
            } else if (d.status === 'CLOSED') {
                CLOSED += 1;
            } else if (d.status === 'SOLVED') {
                SOLVED += 1;
            }
            ALL += 1;
        }
        return [
            {
                type: 'ALL',
                count: ALL,
            },
            {
                type: 'NEW',
                count: NEW,
            },
            {
                type: 'CLOSED',
                count: CLOSED,
            },
            {
                type: 'SOLVED',
                count: SOLVED,
            },
        ];
    };

    useEffect(() => {
        userService.getAllUsers().then((res) => {
            if (res.status === 'success') {
                setUsers(res.data.users);
            }
        });
    }, []);

    return (
        <div className='mx-3'>
            <h2>Doubts</h2>
            <div className="d-flex justify-content-end mb-3">
                <DoubtTicketHeader
                    data={headerTypeData()}
                    onFilterApply={handleFilterApply}
                    onTypeChange={handleTypeChange}
                />
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                onRowClicked={handleRowClick}
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100, 500, 1000]}
                highlightOnHover
                pointerOnHover
                customStyles={{
                    table: {
                        style: {
                            width: '90%',
                            margin: 'auto'
                        }
                    }
                }}
            />
            <Modal show={show} onHide={() => setShow(false)} keyboard={false} fullscreen={true}>
                {selectedDoubt && (
                    <SingleDoubt
                        userId={userId}
                        users={users}
                        ticket={selectedDoubt}
                        onHide={() => setShow(false)}
                    />
                )}
            </Modal>
        </div>
    );
}

export default Doubts;
