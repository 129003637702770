import { useState } from "react";
import { Accordion, useAccordionButton, Table } from "react-bootstrap";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { GroupedByDateContent, Level2Content } from "../../types/contentUsageTypes";
import { icons } from "../../assets/icons";
import formatDuration from "../helpers/timeFormat";

// Custom Toggle for Accordion
function CustomToggle({ children, eventKey, onClick }: { children: any, eventKey: string, onClick: () => void }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    onClick()
  });

  return <div onClick={decoratedOnClick}>{children}</div>;
}

// Accordion Item for Level 1

// Accordion Item for Level 2
const Level2AccordionItem = ({ data }: { data: Level2Content }) => {
  const [isClick, setIsClick] = useState(false);

  const handleToggleClick = () => {
    setIsClick((click) => !click);
  };

  return (
    <Accordion className="my-2 custome-accordian-background-color">
      <CustomToggle eventKey={data.level2Id.toString()} onClick={handleToggleClick}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center"><span className="me-2">{icons.chapter}</span>{data.level2Name}</div>
          <div>{isClick ? <FaChevronDown /> : <FaChevronRight />}</div>
        </div>
      </CustomToggle>

      <Accordion.Collapse eventKey={data.level2Id.toString()}>
        <div className="ms-3">
          {data.content.map((contentItem: any, idx: any) => (
            <ContentItem key={idx} data={contentItem} />
          ))}
        </div>
      </Accordion.Collapse>
    </Accordion>
  );
};


function ContentItem({ data }: { data: any }) {
  const { date, time } = data;

  const getTotalRows = (level1Content: any[]) => {
    return level1Content.reduce((acc: number, level1Item: any) => {
      return acc + level1Item?.content?.reduce((innerAcc: number, level2Content: any) => {
        return innerAcc + (level2Content?.content?.length ?? 0);
      }, 0);
    }, 0);
  };
  const totalRowsForDate = getTotalRows(data.level1Content);

  return (
    <tbody>
      {data.level1Content.map((level1Item: any, level1Idx: number) => {
        const rowsForSubject = level1Item?.content?.reduce((acc: number, level2Content: any) => {
          return acc + level2Content?.content?.length;
        }, 0);

        return level1Item?.content?.map((level2Content: any, level2Idx: number) => {
          return level2Content?.content?.map((contentItem: any, contentItemIdx: number) => {
            return (
              <tr key={`${level1Idx}-${level2Idx}-${contentItemIdx}`}>
                {/* Render date only in the first row of the entire date's data */}

                {level1Idx === 0 && level2Idx === 0 && contentItemIdx === 0 && (

                  <td rowSpan={totalRowsForDate}>
                    {new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}
                  </td>

                )}
                {level1Idx === 0 && level2Idx === 0 && contentItemIdx === 0 && (
                  <td rowSpan={totalRowsForDate}  >{formatDuration(time)}</td>
                )}
                <td>
                  <div className="d-flex align-items-center">
                    {/* Conditional rendering for the icon */}
                    {contentItem.parentAssetId && contentItem.
                      parentAssetType
                      === "VIDEO"
                      ? icons.live_session
                      : contentItem.assetType === "VIDEO" && icons.VIDEO}
                    {contentItem.assetType === "PDF" && icons.PDF}
                    {contentItem.assetType === "SESSION" && icons.LIVE}
                    {contentItem.assetType === "QUIZ" && icons.QUIZ}
                    {contentItem.assetType === "BOOK" && icons.book}
                    {contentItem.assetType === "other" && icons.other}

                    {/* Content name next to the icon */}
                    <span className="ms-2">{contentItem.contentName} ({formatDuration(contentItem.durInSec)})</span>
                  </div>
                </td>
                {/* Render chapter name only for the first row of the chapter's data */}
                {contentItemIdx === 0 && (
                  <td rowSpan={level2Content?.content?.length}>{level2Content?.level2Name}</td>
                )}
                {/* Render subject name only for the first row of the subject's data */}
                {level2Idx === 0 && contentItemIdx === 0 && (

                  <td rowSpan={rowsForSubject}  >{level1Item?.level1Name}</td>
                )}
              </tr>
            );
          });
        });
      })}
    </tbody>
  );
}


// Main Component to display Level1Content[]
const Level1Accordion = ({ data }: { data: GroupedByDateContent[] }) => {

  return (
    <Accordion>
      {data?.length > 0 ?
        <Table  bordered>
          <thead>
            <tr>
              <th>Date</th>
              <th>Duration</th>
              <th>Content</th>
              <th>Chapter</th>
              <th>Subject</th>
            </tr>
          </thead>
          {data?.map((item, idx) => (
            // JSON.stringify(item)
            // <Level1AccordionItem key={idx} data={item} />
            <ContentItem key={idx} data={item} />
          ))}

        </Table>
        : 'No Data found'}
    </Accordion>
  );
};

export default Level1Accordion;
