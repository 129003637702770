import { useEffect, useState, useCallback } from 'react';
import { Row, Col, Form, FloatingLabel, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import toast from 'react-hot-toast';
import { debounce } from '../../utils';
import { axiosClient } from '../../services/axiosClient';
import { BACKEND_URLS } from '../../config';
import courseService from '../../services/ApiServices/course-service';
import { EnrollmentFilteresInterface, metaDataInterface } from '../../types/enrolled-student';
import UploadTTModal from '../modals/upload-tt-modal';

const colors = ['#ff7979', '#badc58', '#7ed6df', '#f9ca24'];

// Custom styles for MultiValue
const customStyles = {
    multiValue: (styles: any, { index }: any) => ({
        ...styles,
        backgroundColor: colors[index % colors.length],
        color: 'white',
        borderRadius: '4px',
    }),
    multiValueLabel: (styles: any) => ({
        ...styles,
        color: 'white',
    }),
    multiValueRemove: (styles: any) => ({
        ...styles,
        color: 'white',
        ':hover': {
            backgroundColor: 'darkred',
            color: 'white',
        },
    }),
};


const MultiValue = (props: any) => {
    const color = colors[props.index % colors.length];
    return (
        <components.MultiValue {...props}>
            <span
                style={{
                    backgroundColor: color,
                    color: 'white',
                    padding: '0 5px',
                    borderRadius: '4px',
                }}
            >
                {props.data.label}
            </span>
        </components.MultiValue>
    );
};

interface EnrolledStudentsHeaderInterface {
    setFilter: React.Dispatch<React.SetStateAction<EnrollmentFilteresInterface>>;
    metaData: metaDataInterface;
    filters: EnrollmentFilteresInterface;
}

function EnrolledStudentsHeader({ setFilter, metaData, filters }: EnrolledStudentsHeaderInterface) {
    const [allCourses, setAllCourses] = useState([]);
    const [allGroups, setAllGroups] = useState([]);
    const [mentors, setMentors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleHideModal = () => setShowModal(false);
    const handleConfirm = () => {
        // Add your confirmation logic here
        console.log('Confirmed!');
        setShowModal(false); // Close modal after confirmation
    };

    const debouncedSearch = useCallback(
        debounce((val: string) => setFilter((prev: any) => ({ ...prev, name: val })), 200),
        [setFilter]
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [coursesResponse, mentorsResponse] = await Promise.all([
                    courseService.getAllCourses(),
                    axiosClient.get(BACKEND_URLS.GET_USERS + '?isStaff=true&role=mentor'),
                ]);

                if (coursesResponse.status === 'success') {
                    setAllCourses(
                        coursesResponse.data.courses.map((el) => ({
                            label: el.name,
                            value: el.id,
                        })) as any
                    );
                    setAllGroups(
                        coursesResponse.data.courses
                            .filter((el: any) => el.group)
                            .map((el: any) => ({ value: el.group, label: el.group })) as any
                    );
                }

                const mentorOpts = mentorsResponse.data.data.users.map((user: any) => ({
                    value: user.id,
                    label: `${user.username} (${user.name})`,
                }));
                setMentors(mentorOpts);
            } catch (error) {
                toast.error('Error fetching data.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="p-4 bg-light">
            <Row className="mb-4">
                <Col xs={12} sm={6} md={4} className="mb-2">
                    <FloatingLabel controlId="search" label="Search by Name/Number/Email">
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Search by Name/Number/Email"
                            onChange={(e) => debouncedSearch(e.target.value)}
                        />
                    </FloatingLabel>
                </Col>

                <Col xs={12} sm={6} md={4} className="mb-2">
                    <FloatingLabel controlId="academicStatus" label="Academic Status">
                        <Form.Select
                            onChange={(e) =>
                                setFilter((prev: any) => ({ ...prev, status: e.target.value }))
                            }
                            value={filters.status}
                        >
                            <option value="ALL">All</option>
                            <option value="HASMENTORSHIP">Active With Mentorship</option>
                            <option value="NOMENTORSHIP">Active Without Mentorship</option>
                            <option value="INACTIVE">Inactive</option>
                            <option value="EXPIRED">Course Ended</option>
                        </Form.Select>
                    </FloatingLabel>
                </Col>

                <Col xs={12} sm={6} md={4} className="mb-2">
                    <FloatingLabel controlId="onboardingStatus" label="Onboarding Status">
                        <Form.Select
                            onChange={(e) =>
                                setFilter((prev: any) => ({ ...prev, process: e.target.value }))
                            }
                            value={filters.process}
                        >
                            <option value="ALL">All ({metaData.onboarded + metaData.in_process + metaData.not_started})</option>
                            <option value="ONBOARDED">Onboarded ({metaData.onboarded})</option>
                            <option value="INPROGRESS">In Progress ({metaData.in_process})</option>
                            <option value="NOTSTARTED">Not Started ({metaData.not_started})</option>
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col xs={12} sm={6} md={4} className="mb-2">
                    <Select
                        placeholder="Select Courses"
                        onChange={(selected) =>
                            setFilter((prev: any) => ({
                                ...prev,
                                course_id: selected.map((s: any) => s.value),
                            }))
                        }
                        options={allCourses}
                        isMulti
                        styles={customStyles}
                        components={{ MultiValue }}
                        isLoading={isLoading}
                    />
                </Col>

                <Col xs={12} sm={6} md={4} className="mb-2">
                    <Select
                        placeholder="Select Groups"
                        onChange={(selected) =>
                            setFilter((prev: any) => ({
                                ...prev,
                                group: selected.map((s: any) => s.value),
                            }))
                        }
                        options={allGroups}
                        isMulti
                        styles={customStyles}
                        isLoading={isLoading}
                    />
                </Col>

                <Col xs={12} sm={6} md={4} className="mb-2">
                    <Select
                        placeholder="Select Mentors"
                        onChange={(selected) =>
                            setFilter((prev: any) => ({
                                ...prev,
                                mentor_id: selected.map((s: any) => s.value),
                            }))
                        }
                        options={mentors}
                        isMulti
                        styles={customStyles}
                        isLoading={isLoading}
                    />
                </Col>
                <Col md="auto" className="pt-3 d-flex justify-content-end">
                    <Button
                        size="sm"
                        variant="primary"
                        type="button" // Changed type to "button" as it's not a form submit
                        style={{ width: '140px' }}
                        onClick={handleShowModal}
                    >
                        Upload Time Table
                    </Button>
                </Col>

                {/* Modal Component */}
                <UploadTTModal
                    show={showModal}
                    onHide={handleHideModal}
                    onConfirm={handleConfirm}

                />
            </Row>
        </div>
    );
}

export default EnrolledStudentsHeader;
