
import { Modal, Button } from 'react-bootstrap';
import { getMediaUrl, pdfUploadHelper, reciveDateTimeFormate } from '../../utils/helpers'

interface UploadTTModalProps {
    show: boolean;
    onHide: () => void;
    onConfirm: () => void;
    title?: string;
    bodyText?: string;
    confirmText?: string;
    cancelText?: string;
}
const handleFileChange = (files: FileList | null) => {
    pdfUploadHelper(files?.[0]).then((key) => {

    });
};
const handleNameChange = (name: string) => {
    console.log('Entered name:', name);
};

const UploadTTModal: React.FC<UploadTTModalProps> = ({
    show,
    onHide,
    onConfirm,
    title = "Upload Time Table",
    bodyText = "Are you sure you want to upload the timetable?",
    confirmText = "Upload",
    cancelText = "Cancel",
}) => (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {/* File Input Section */}
            <div className="edit-image-btn d-flex flex-column justify-content-right align-items-start m-2">
              
                <input
                    type="file"
                    className="file-input"
                    accept=".pdf" 
                    onChange={(e) => handleFileChange(e.target.files)}
                />
            </div>

            {/* Text Input Section */}
            <div className="edit-image-btn d-flex flex-column justify-content-center align-items-center m-2">
            
                <input
                    type="text"
                    className="form-control"
                    placeholder="Enter file name" // Adds a placeholder for better UX
                    onChange={(e) => handleNameChange(e.target.value)} // Separate handler for text input
                />
            </div>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
                {cancelText}
            </Button>
            <Button variant="danger" onClick={onConfirm}>
                {confirmText}
            </Button>
        </Modal.Footer>
    </Modal>
);

export default UploadTTModal;
