"use client";
import { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap"; // Import Dropdown
import { FRONTEND_URLS } from "../../config";
import DataTable from "react-data-table-component";
import AllQuizzesHeader from "./AllQuizzesHeader";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { QuizItem } from "../../types/quizTypes";
import QuizService from "../../services/ApiServices/QuizService";

const AllQuizzes = () => {
  const [totalRows, setTotalRows] = useState(0);
  const [size, setSize] = useState(100);
  const [loading, setLoading] = useState(true);
  const [quizzes, setQuizzes] = useState<QuizItem[]>([]);
  const [quizType, setQuizType] = useState("");
  const [subject, setSubject] = useState("");
  const [chapter, setChapter] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");

  const columns = [
    {
      name: "ID",
      selector: (row: QuizItem) => row.id,
      sortable: true,
      sortField: "id",
      style: {
        width: '50px'
      }
    },
    {
      name: "Name",
      selector: (row: QuizItem) => row.internalName,
      sortable: true,
      sortField: "internalName",
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.internalName} </div>
    },
    {
      name: "Type",
      selector: (row: QuizItem) => row.quizType,
      sortField: "quizType",
      sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.quizType} </div>
    },
    {
      name: "Created On",
      selector: (row: QuizItem) => row.createdOn.split("T")[0],
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.createdOn.split("T")[0]} </div>
    },
    {
      name: "Actions",
      cell: (row: QuizItem) => (
        <Dropdown align="end" drop="end">
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <IoEllipsisVerticalSharp />
          </Dropdown.Toggle>
          {/* <Dropdown.Toggle variant="light" className="p-0">
            <IoEllipsisVerticalSharp size={24} />
          </Dropdown.Toggle> */}

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                const url =
                  row.quizType === "QUIZ"
                    ? FRONTEND_URLS.EXTERNAL.STUDENT_APP.BASE + FRONTEND_URLS.EXTERNAL.STUDENT_APP.QUIZ + "/" + row.id + "/inst"
                    : FRONTEND_URLS.EXTERNAL.STUDENT_APP.BASE + FRONTEND_URLS.EXTERNAL.STUDENT_APP.PRACTICE + "/" + row.id;
                window.open(url, "_blank");
              }}
            >
              View
            </Dropdown.Item>

            <Dropdown.Item
              onClick={() =>
                window.open(FRONTEND_URLS.EXTERNAL.STUDENT_APP.BASE + FRONTEND_URLS.EXTERNAL.STUDENT_APP.PRACTICE + "/" + row.id + "?proof-read=true", "_blank")
              }
            >
              Proof Read
            </Dropdown.Item>

            <Dropdown.Item onClick={() => window.open("/module-pdf/" + row.id, "_blank")}>
              PDF
            </Dropdown.Item>

            <Dropdown.Item
              onClick={() =>
                window.open(
                  FRONTEND_URLS.USER_SOLVE_CAST.replace(":quizId", row.id.toString()),
                  "_blank"
                )
              }
            >
              Solve-cast
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      ignoreRowClick: true, // Prevent row click when clicking on actions
      button: true,
    },
  ];

  async function getAllQuiz() {
    setLoading(true);
    const res = await QuizService.getAll(
      `?page=${page}&limit=${size}&quizType=${quizType}&internalName=${search.trim()}&sort=${sort}&dir=${sortDirection}`
    );

    if (res.status === "success") {
      setQuizzes(res.data);
      setTotalRows(res.meta.total || 0);
      setLoading(false);
    }
  }

  const broadcast = new BroadcastChannel('contentUpdates');

  useEffect(() => {
    broadcast.onmessage = (event) => {
      if (event.data.type === 'DELETE_CONTENT') {
        const quizId = event.data.quizId
        setQuizzes((prev) => prev.filter((quiz) => quiz.id !== quizId))
      }
    };

    return () => broadcast.close();
  }, []);


  useEffect(() => {
    getAllQuiz();
  }, [size, quizType, subject, chapter, search, page, sort, sortDirection]);


  const handleRowClick = (row: QuizItem) => {
    if (window) {
      const url =
        row.quizType.toUpperCase() === "QUIZ"
          ? FRONTEND_URLS.CONTENTS.EDIT_QUIZ + "/" + row.id
          : FRONTEND_URLS.CONTENTS.PRACTICE_QUIZ_EDIT + "/" + row.id;
      window.open(url, "_blank")?.focus();
    }
  };

  return (
    <div className="container">
      <DataTable
        className="custom-table"
        title="All Quizzes"
        columns={columns}
        data={quizzes}
        progressPending={loading}
        pagination
        paginationPerPage={100}
        paginationRowsPerPageOptions={[100, 500, 1000]}
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={setSize}
        onChangePage={(e) => setPage(e)}
        onSort={(column, dir) => {
          if (column.sortField) setSort(column.sortField);
          setSortDirection(dir);
        }}
        subHeader
        subHeaderComponent={
          <AllQuizzesHeader
            setQuizType={setQuizType}
            setSubject={setSubject}
            setChapter={setChapter}
            setSearch={setSearch}
          />
        }
        onRowClicked={handleRowClick}
      />
    </div>
  );
};

export default AllQuizzes;
