import { useEffect, useRef, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { useLoaderData, useNavigate } from 'react-router-dom';
import MainBtn from '../../../components/btn/main-btn';
import { BACKEND_URLS, FRONTEND_URLS } from '../../../config';
import { ModalsConstants } from '../../../Redux/models/models-constants';
import { axiosClient } from '../../../services/axiosClient';
import { FilterKeys, NodeApiInterface, NodeApiType, NodeSettings } from '../../../types/node-types';
import CreateNode from './create-node';
import toast from 'react-hot-toast';
import { LevelInterface } from '../../../types/ApiTypes';
import { TargetItem, ClassItem } from '../../../types/course.types';
import generalService from '../../../services/ApiServices/general-service';
import targetService from '../../../services/ApiServices/target-service';
import studentClassService from '../../../services/ApiServices/student-class-service';
import courseService from '../../../services/ApiServices/course-service';
import './styles.css';
import Select from 'react-select';

export async function loader(): Promise<NodeApiInterface> {
    const limit = 100;
    const pageNumber = 1;

    const data = await axiosClient.get(
        BACKEND_URLS.NODES
        // + `?limit=${limit}&page=${pageNumber}`
    );
    return data.data;
}

const CustomeTableHead = ({
    value,
    updateValue,
    name,
    id,
    selectData,
}: {
    name: string;
    value: string;
    updateValue: (value: string, name: FilterKeys) => void;
    id: FilterKeys;
    selectData?: { name: string }[];
}) => (
    <div className="form-floating mb-3">
        <Form.Select
            id={`floating-${id}`}
            size="sm"
            value={value}
            onChange={(e) => updateValue(e.target.value, id)}
        >
            <option value=""></option>
            {selectData?.map((data, index) => (
                <option key={index} value={data.name}>
                    {data.name}
                </option>
            ))}
        </Form.Select>
        <label htmlFor={`floating-${id}`}>{name}</label>
    </div>
);

function Node() {
    const data = useLoaderData() as NodeApiInterface;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [nodes, setNodes] = useState<NodeApiType[]>(data.data.nodes);
    const [node, setNode] = useState<NodeApiType | null>(null);
    const [nodeName, setNodeName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(1000);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(Number(data.meta.total));

    const [subjectData, setSubjectData] = useState<LevelInterface[]>([]);
    const [targets, setTargets] = useState<TargetItem[]>([]);
    const [classes, setClasses] = useState<ClassItem[]>([]);
    const [settingsData, setSettingsData] = useState<NodeSettings | null>(null);
    const [phases, setPhases] = useState<string[]>([]);
    const [groups, setGroups] = useState<string[]>([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen((prev) => !prev);
    };

    const [filters, setFilters] = useState({
        level1InternalName: '',
        class: '',
        target: '',
        category: '',
        itemType: '',
        branding: '',
        targetYear: '',
        phase: '',
        group: '',
        internalName: '',
    });

    const debouncedFilterTimeout = useRef<NodeJS.Timeout | null>(null);

    const handleFilters = (value: string, name: FilterKeys) => {
        const newFilters = { ...filters, [name]: value };

        const filteringKeys = Object.keys(newFilters).filter(
            (key) => newFilters[key as keyof typeof newFilters] !== ''
        );
        setFilters(newFilters);

        // If no filters are applied, show all nodes
        if (Object.values(newFilters).every((filter) => filter === '')) {
            setNodes(data.data.nodes);
            return;
        }

        // Debounce the filter logic to avoid excessive re-renders
        if (debouncedFilterTimeout.current) {
            clearTimeout(debouncedFilterTimeout.current);
        }

        debouncedFilterTimeout.current = setTimeout(() => {
            // Filter nodes based on the new filters
            const filteredNodes = data.data.nodes.filter((node) => {
                const isContain: boolean[] = [];

                for (let index = 0; index < filteringKeys.length; index++) {
                    const filterKey = filteringKeys[index] as FilterKeys;
                    const filter = newFilters[filterKey];

                    const nodeString = node[filterKey];

                    if (!nodeString) {
                        isContain.push(false);
                        continue;
                    }
                    isContain.push(nodeString.toLowerCase().includes(filter.toLowerCase()));
                }
                if (isContain.includes(false)) {
                } else {
                    return node;
                }
            });

            // If no nodes match the filter criteria, show an empty node with default values
            setNodes(
                filteredNodes.length
                    ? filteredNodes
                    : [
                          {
                              id: 0,
                              internalName: '',
                              level0Id: 0,
                              level0Name: '',
                              level1Id: 0,
                              level1Name: '',
                              createdOn: '',
                              group: '',
                              phase: '',
                              branding: '',
                              itemType: '',
                              targetYear: '',
                              category: '',
                              class: '',
                              target: '',
                              level0InternalName: '',
                              level1InternalName: '',
                          },
                      ]
            );
        }, 500); // Adjust debounce timeout as necessary
    };

    const targetYears = Array.from({ length: 5 }, (_, i) => new Date().getFullYear() + i + 0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [
                    subjectGroupsResponse,
                    subjectResponse,
                    settingsResponse,
                    targetResponse,
                    classResponse,
                    courseResponse,
                ] = await Promise.all([
                    axiosClient.get(BACKEND_URLS.LEVEL + 0),
                    axiosClient.get(BACKEND_URLS.LEVEL + 1),
                    generalService.getSettings(['categories', 'item_types', 'course_brandings']),
                    targetService.getAll(),
                    studentClassService.getAll(),
                    courseService.getAllCourses(),
                ]);
                if (targetResponse.status == 'success') setTargets(targetResponse.data.targets);
                if (classResponse.status == 'success') setClasses(classResponse.data.classes);
                if (settingsResponse.status == 'success') setSettingsData(settingsResponse.data);
                if (courseResponse.status === 'success') {
                    const p = new Set<string>();
                    const g = new Set<string>();
                    courseResponse.data.courses.forEach((course) => {
                        const phase = course.phase;
                        const group = course.group;
                        if (phase) p.add(phase);
                        if (group) g.add(group);
                    });
                    setPhases([...p]);
                    setGroups([...g]);
                }
                setSubjectData(subjectResponse.data.data.level);
            } catch (error: any) {
                toast.error(error.message);
            }
        };

        fetchData();
    }, []);

    const columns = [
        {
            name: 'Id',
            selector: (row: NodeApiType) => row.id,
            cell: (row: NodeApiType) => <div>{row.id}</div>,
            width: '5rem',
            sortable: true,
        },
        {
            name: 'Internal Name',
            selector: (row: NodeApiType) => row.internalName,
            cell: (row: NodeApiType) => (
                <div
                    data-tag="allowRowEvents"
                    style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
                >
                    {' '}
                    {row.internalName}{' '}
                </div>
            ),
        },
        {
            name: 'Subejct',
            selector: (row: NodeApiType) => row.level1InternalName || row.level1Name,
            cell: (row: NodeApiType) => (
                <div
                    data-tag="allowRowEvents"
                    style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
                >
                    {row.level1InternalName || row.level1Name}
                </div>
            ),
        },
        {
            name: 'Created On',
            selector: (row: NodeApiType) => row.createdOn?.split('T')[0],
            sortable: true,
            cell: (row: NodeApiType) => (
                <div
                    data-tag="allowRowEvents"
                    style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
                >
                    {' '}
                    {row.createdOn?.split('T')[0]}{' '}
                </div>
            ),
            width: '10rem',
        },
        {
            name: 'Copy Node',
            selector: (row: NodeApiType) => row?.branding ?? '',
            cell: (row: NodeApiType) => (
                <button
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                        setNode(row);
                        handleCreateNewNode();
                    }}
                >
                    Copy
                </button>
            ),

            width: '10rem',
        },
    ];

    const handleNodeCancel = () => {
        setNode(null);
    };

    const handleCreateNewNode = () => {
        dispatch({ type: ModalsConstants.IS_NODE_MODAL, payload: true });
    };

    const handleSearch = async () => {
        setLoading(true);
        try {
            const data = await axiosClient.get(
                BACKEND_URLS.NODES + `?internalName=${nodeName.trim()}`
            );
            setNodes(data.data.data.nodes);
            setTotalRows(data.data.meta.total);
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleNodesPerPage = async (newPerPage: number) => {
        setLoading(true);
        setRowsPerPage(newPerPage);
        const data = await axiosClient.get(BACKEND_URLS.NODES + `?limit=${newPerPage}&page=1`);
        setNodes(data.data.data.nodes);
        setTotalRows(data.data.meta.total);
        setLoading(false);
    };

    const handlePgaeChange = async (pageNumber: number) => {
        setLoading(true);
        const data = await axiosClient.get(
            BACKEND_URLS.NODES + `?limit=${rowsPerPage}&page=${pageNumber}`
        );
        setNodes(data.data.data.nodes);
        setTotalRows(data.data.meta.total);
        setLoading(false);
    };

    return (
        <div className="d-flex">
            <div className={`flex-grow-1 ps-2 content ${isSidebarOpen ? ' active' : ''}`}>
                <CreateNode
                    node={node}
                    handleNodeCancel={handleNodeCancel}
                    isCopyNode={node?.internalName ? true : false}
                    copingNodeId={node?.id}
                />
                <h2>Node</h2>

                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSearch();
                    }}
                    className="d-flex"
                >
                    <Form.Group
                        as={Col}
                        controlId="formSearch"
                        className="d-flex align-items-center mb-3"
                    >
                        <Form.Control
                            type="text"
                            placeholder="Enter node name"
                            className="me-2 w-25"
                            onChange={(e) => setNodeName(e.target.value)}
                        />
                        <Button type="submit" size="sm">
                            Search
                        </Button>
                    </Form.Group>
                    <div className="w-25 me-auto">
                        <button
                            className="ms-auto btn btn-success btn-sm px-3 me-5"
                            onClick={toggleSidebar}
                            type="button"
                        >
                            {isSidebarOpen ? 'Hide Filters' : 'Show Filters'}
                        </button>
                    </div>
                </Form>

                <DataTable
                    columns={columns}
                    data={nodes}
                    pagination
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[100, 500, 1000]}
                    paginationServer
                    onChangeRowsPerPage={handleNodesPerPage}
                    onChangePage={handlePgaeChange}
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    customStyles={{
                        table: {
                            style: {
                                width: '90%',
                                margin: 'auto',
                            },
                        },
                    }}
                    fixedHeader
                    onRowClicked={(row) => {
                        window.open(`${FRONTEND_URLS.CONTENTS.EDIT_NODE}/${row.id}`, '_blank');
                    }}
                />
            </div>
            <div className={`sidebar ${isSidebarOpen ? 'active' : ''}`}>
                <h5 className="mb-2">Filter Nodes</h5>
                <Form.Group>
                    <div className="form-floating mb-3">
                        <Select
                            options={
                                subjectData?.map((data) => ({
                                    label: data.internalName,
                                    value: data.internalName,
                                })) as any
                            }
                            placeholder="Subject"
                            isSearchable
                            isClearable
                            onChange={(e: any) => {
                                handleFilters(e?.value || '', 'level1InternalName');
                            }}
                        />
                    </div>
                </Form.Group>
                <Form.Group className="mt-3">
                    <CustomeTableHead
                        name="Class"
                        updateValue={handleFilters}
                        value={filters['class']}
                        id="class"
                        selectData={classes}
                    />
                </Form.Group>
                <Form.Group className="mt-3">
                    <CustomeTableHead
                        name="Exam"
                        updateValue={handleFilters}
                        value={filters['target']}
                        id="target"
                        selectData={targets}
                    />
                </Form.Group>
                <Form.Group className="mt-3">
                    <CustomeTableHead
                        name="Category"
                        updateValue={handleFilters}
                        value={filters['category']}
                        id="category"
                        selectData={settingsData?.categories.map((cat) => ({ name: cat }))}
                    />
                </Form.Group>
                <Form.Group className="mt-3">
                    <CustomeTableHead
                        name="Item Type"
                        updateValue={handleFilters}
                        value={filters['itemType']}
                        id="itemType"
                        selectData={settingsData?.item_types.map((item) => ({ name: item }))}
                    />
                </Form.Group>
                <Form.Group className="mt-3">
                    <CustomeTableHead
                        name="Branding"
                        updateValue={handleFilters}
                        value={filters['branding']}
                        id="branding"
                        selectData={settingsData?.course_brandings.map((brand) => ({
                            name: brand,
                        }))}
                    />
                </Form.Group>
                <Form.Group className="mt-3">
                    <CustomeTableHead
                        name="Target Year"
                        updateValue={handleFilters}
                        value={filters['targetYear']}
                        id="targetYear"
                        selectData={targetYears.map((year) => ({ name: `${year}` }))}
                    />
                </Form.Group>
                <Form.Group className="mt-3">
                    <CustomeTableHead
                        name="Phase"
                        updateValue={handleFilters}
                        value={filters['phase']}
                        id="phase"
                        selectData={phases.map((phase) => ({ name: phase }))}
                    />
                </Form.Group>
                <Form.Group className="mt-3">
                    <CustomeTableHead
                        name="Group"
                        updateValue={handleFilters}
                        value={filters['group']}
                        id="group"
                        selectData={groups.map((group) => ({ name: group }))}
                    />
                </Form.Group>
            </div>
            <MainBtn onClick={handleCreateNewNode} message="make new node." />
        </div>
    );
}

export default Node;
