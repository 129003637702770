import React, { useState } from 'react';
import { Button, Form, ProgressBar } from 'react-bootstrap';
import { pdfUploadHelper, uploadVideoOnVdoCipherDirect } from '../../utils/helpers';
import { VideoSource } from '../../types/types';


interface LiveManagementInterface {
    closeModal: () => void,
    sessionId: string
}


const LiveManagementContent = ({ closeModal, sessionId }: LiveManagementInterface) => {
    const [videoProgress, setVideoProgress] = useState(0);
    const [videoId, setVideoId] = useState('');
    const [pdfProgress, setPdfProgress] = useState(0);
    const [pdfUrl, setPdfUrl] = useState('');

    const handleVideoUpload = async (file: File) => {
        const data = await uploadVideoOnVdoCipherDirect({
            file,
            vdoCipherId: VideoSource.VDOCIPHER_SECURE,
            onUploadProgress: setVideoProgress,
        });
        if (data?.videoId) {
            setVideoId(data.videoId);
        }
    };

    const handlePdfUpload = async (file: File) => {
        const pdfUrl = await pdfUploadHelper(file, setPdfProgress);
        setPdfUrl(pdfUrl);
    };

    const handleSubmit = () => {
        closeModal()
    };

    return (
        <>
            <Form.Group controlId="videoUpload" className="mb-3 row align-items-center">
                <Form.Label className="col-sm-3 col-form-label">Upload Video File</Form.Label>
                <div className="col-sm-9">
                    <Form.Control
                        type="file"
                        name="videoFile"
                        accept="video/*"
                        onChange={(event) => {
                            const input = event.target as HTMLInputElement;
                            const file = input.files?.[0];
                            if (file) {
                                handleVideoUpload(file);
                            }
                        }}
                    />
                    {videoId ? (
                        <div>Video Id: {videoId}</div>
                    ) : (
                        <ProgressBar now={videoProgress} label={`${videoProgress}%`} animated striped />
                    )}
                </div>
            </Form.Group>

            <Form.Group controlId="pdfUpload" className="mb-3 row align-items-center">
                <Form.Label className="col-sm-3 col-form-label">Upload PDF File</Form.Label>
                <div className="col-sm-9">
                    <Form.Control
                        type="file"
                        name="pdfFile"
                        accept='application/pdf'
                        onChange={(event) => {
                            const input = event.target as HTMLInputElement;
                            const file = input.files?.[0];
                            if (file) {
                                handlePdfUpload(file);
                            }
                        }}
                    />
                    {pdfUrl ? (
                        <div>PDF Url: {pdfUrl}</div>
                    ) : (
                        <ProgressBar now={pdfProgress} label={`${pdfProgress}%`} animated striped />
                    )}
                </div>
            </Form.Group>

            <Button onClick={handleSubmit}>Submit</Button>
        </>
    );
};

export default LiveManagementContent;
