import { MessagePayload, TicketPayload, TicketResponse } from '../../types/DoubtTypes';
import ApiService, { ApiResponse } from './api-service';

class DoubtService extends ApiService {
    constructor() {
        super('/tickets');
    }

    createTicket(data: TicketPayload) {
        return this.postData('/', data);
    }

    updateTicket(ticketId: number, data: Partial<TicketPayload>) {
        return this.putData(`/${ticketId}`, data);
    }

    getAllTickets(): ApiResponse<{ tickets: Omit<TicketResponse, 'message'>[]; userId: number }> {
        return this.getData('/');
    }

    getTicket(ticketId: number): ApiResponse<{ ticket: TicketResponse }> {
        return this.getData(`/${ticketId}`);
    }

    sendMessage(ticketId: number, data: MessagePayload) {
        return this.postData(`/${ticketId}/messages`, data);
    }

    deleteMessage(ticketId: number, messageId: number) {
        return this.deleteData(`/${ticketId}/messages/${messageId}`);
    }

    assignUser(ticketId: number, data: { assignmentId: number; staffId: number }) {
        return this.postData(`/${ticketId}/assignment`, data);
    }
}

export default new DoubtService();
